import React, { useEffect } from 'react';
import Dynamic from './cms/Dynamic';
import '../scss/site-banner.scss';
import EditField from './cms/EditField';
import Spinner from './Spinner';
import ErrorNotice from './ErrorNotice';

const EditSiteBanner = ({ data = {}, invalid, updatePreview, setValidator }) => {
  useEffect(() => {
    setValidator((data = {}) => {
      const errors = {};
      if (!(data?.message ?? '').trim().length) {
        errors.message = 'Please enter a message';
      }
      return errors;
    });
  }, [setValidator]);

  return <>
    <EditField label="Message:" value={data.message ?? ''} error={invalid?.message} onChange={(message) => updatePreview({ ...data, message })}/>
  </>;
};

const SiteBanner = ({ loading, error, data: { message } = {} }) => {
  return (
    <div className="site-banner">
      { loading ? <Spinner small={true} dark={false} /> : null}
      {error ? <ErrorNotice error={error} /> : null}
      <span className="site-banner__text">{message}</span>
    </div>
  );
};

const DynamicSiteBanner = ({ id }) => {
  return <Dynamic id={id} typename="SiteBanner" cacheEnabled={true} component={SiteBanner} editComponent={EditSiteBanner} />;
}

export { DynamicSiteBanner as default, SiteBanner }