import React, { useEffect, useState } from 'react';
import uuid from './lib/uuid';
import Dynamic from './cms/Dynamic';
import EditField from './cms/EditField';
import EditList from './cms/EditList';
import { slugify, uncamelCase } from './lib/formatters';
import ErrorNotice from './ErrorNotice';
import Spinner from './Spinner';
import useSearch from './hooks/useSearch';
import EditGroup from './cms/EditGroup';

const historyFormatters = [
  {
    pattern: /^.*\.id$/,
    formatter: () => '',
  },
  {
    pattern: /^(types\.\[\d+])\.(.*)/,
    formatter: ({ values, replace: [match, path, rest] }) => `${values[`${path}.name`]} ${uncamelCase(rest)}` || match,
  }
];

const EditProductTypes = ({ data = {}, invalid, updatePreview, setValidator }) => {
  const [selectedId, setSelectedId] = useState();
  const [selected, setSelected] = useState();
  const [items, setItems] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [{ loading, error, products = [], run }, { updateFilters }] = useSearch({ limit: 1, defer: true });

  const onSelect = (id) => {
    if (id === 'add') {
      const newType = { id: uuid(), name: '**New**' };
      updatePreview({ ...data, types: [...(data.types || []), newType] });
      setSelectedId(newType.id);
      setIsNew(true);
    } else {
      setSelectedId(id);
      setIsNew(false);
      updateFilters([`product-types#${id}`]);
      run();
    }
  };

  const onDelete = (deleteId) => {
    const newTypes = [...(data.types || [])].filter(({ id }) => id !== deleteId);
    updatePreview({ ...data, types: newTypes });
  };

  useEffect(() => {
    setItems([ ...(data.types || []), { id: 'add', name: '+ Add new type' }]);
  }, [data.types, setItems]);

  useEffect(() => {
    setSelected(items.find(({ id }) => id === selectedId));
  }, [selectedId, items, setSelected]);

  useEffect(() => {
    setValidator((data = {}) => {
      const errors = {};
      if (!(data?.defaultHeader ?? '').trim().length) {
        errors.defaultHeader = 'Please a default header';
      }
      if (!(data?.defaultSubHeader ?? '').trim().length) {
        errors.defaultSubHeader = 'Please enter a default sub header';
      }
      if (!(data?.mixedHeader ?? '').trim().length) {
        errors.mixedHeader = 'Please enter a mixed header';
      }
      if (!(data?.mixedSubHeader ?? '').trim().length) {
        errors.mixedSubHeader = 'Please enter a mixed header';
      }
      if (!data?.types?.length) {
        errors.types = 'Please enter some product types';
      } else {
        data.types.forEach(({ id, name, header, subHeader }) => {
          if (!(name ?? '').trim().length || name === '**New**') {
            errors[`types-${id}`] = errors[`types-${id}`] || {}
            errors[`types-${id}`].name = 'Please enter a name';
            errors.types = 'Please check the items in the list';
          }
          if (!(header ?? '').trim().length) {
            errors[`types-${id}`] = errors[`types-${id}`] || {}
            errors[`types-${id}`].header = 'Please enter a header';
            errors.types = 'Please check the items in the list';
          }
          if (!(subHeader ?? '').trim().length) {
            errors[`types-${id}`] = errors[`types-${id}`] || {}
            errors[`types-${id}`].subHeader = 'Please enter a sub header';
            errors.types = 'Please check the items in the list';
          }
        });
      }
      return errors;
    });
  }, [setValidator])

  const updateSelected = (values) => {
    const newTypes = [...(data.types || [])].map(item => ({ ...item }));
    const type = newTypes.find(({ id }) => id === selectedId);
    if (type) {
      Object.keys(values).forEach(key => type[key] = values[key]);
      updatePreview({ ...data, types: newTypes });
    }
  };

  return <>
    <EditField label="Default Header:" value={data.defaultHeader ?? ''} error={invalid?.defaultHeader} onChange={(defaultHeader) => updatePreview({ ...data, defaultHeader })} />
    <EditField label="Default Sub Header:" value={data.defaultSubHeader ?? ''} error={invalid?.defaultSubHeader} onChange={(defaultSubHeader) => updatePreview({ ...data, defaultSubHeader })} />
    <EditField label="Mixed Header:" value={data.mixedHeader ?? ''} error={invalid?.mixedHeader} onChange={(mixedHeader) => updatePreview({ ...data, mixedHeader })} />
    <EditField label="Mixed Sub Header:" value={data.mixedSubHeader ?? ''} error={invalid?.mixedSubHeader} onChange={(mixedSubHeader) => updatePreview({ ...data, mixedSubHeader })} />
    <EditGroup>
      <EditList invalid={items?.map(({ id }) => id).filter((id) => invalid && `types-${id}` in invalid)} showDelete={true} deleteEnabled={!!selected && (isNew || (!loading && !error && !products?.length))} label="Type:" items={items} error={invalid?.types} selectedId={selectedId} onChange={onSelect} onDelete={onDelete} />
      { selected ?
        <>
          <EditField label="Name:" value={selected.name ?? ''} error={invalid?.[`types-${selectedId}`]?.name} onChange={(name) => updateSelected({ name })} />
          <EditField label="Header:" value={selected.header ?? ''} error={invalid?.[`types-${selectedId}`]?.header} onChange={(header) => updateSelected({ header })} />
          <EditField label="Sub Header:" value={selected.subHeader ?? ''} error={invalid?.[`types-${selectedId}`]?.subHeader} onChange={(subHeader) => updateSelected({ subHeader })} />
        </>
        : null }
    </EditGroup>
  </>;
};

const ProductTypes = ({ loading, error, data, updateProductTypes, activeFilters, toggleFilter, clearFilters }) => {
  const onToggleFilter = (filter) => toggleFilter(slugify(filter.name));
  const filters = data?.types ?? [];
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data) {
      const { types, ...rest } = data;
      updateProductTypes({ ...rest, productTypes: (types || []).reduce((obj, type) => ({ ...obj, [slugify(type.name)]: type }), {}) });
    }
  }, [data, updateProductTypes]);

  useEffect(() => {
    if (open) {
      const onClick = (e) => {
        setOpen(false);
      };
      window.addEventListener('click', onClick);
      return () => {
        window.removeEventListener('click', onClick);
      };
    }
  }, [open]);

  return (
    <aside className={`shop-page__search__filters ${open ? 'shop-page__search__filters--open' : ''}`}>
      <section className="shop-page__search__filters__options">
        <h4 className="shop-page__search__filters__options__header">Filter by:</h4>
        <button className="shop-page__search__filters__options__button" onClick={(e) => setOpen(!open)}>Filter{activeFilters.length? <span className="shop-page__search__filters__options__button__count">({activeFilters.length})</span> : null}</button>
        {activeFilters.length ? <button onClick={clearFilters} className="shop-page__search__filters__options__clear">Clear All</button> : null}
        <ul className="shop-page__search__filters__options__items">
          {loading ? <Spinner center={true} small={true} dark={true} /> : null}
          {error ? <ErrorNotice error={error} /> : null}
          {
            filters.map((filter) =>
              <li
                key={filter.id}
                className={`shop-page__search__filters__options__items__item ${activeFilters.includes(slugify(filter.name)) ? 'shop-page__search__filters__options__items__item--active' : ''}`}
                onClick={(e) => onToggleFilter(filter)}
              >
                <span className="shop-page__search__filters__options__items__item__indicator"/>
                <span className="shop-page__search__filters__options__items__item__label">{filter.name}</span>
              </li>
            )
          }
        </ul>
      </section>
    </aside>
  );
};

const DynamicProductTypes = ({ id, ...rest }) => {
  return <Dynamic
    id={id} {...rest}
    typename="ProductTypes"
    cacheEnabled={true}
    component={ProductTypes}
    editComponent={EditProductTypes}
    historyFormatters={historyFormatters}
  />;
}

export { DynamicProductTypes as default, ProductTypes }