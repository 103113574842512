import React from 'react';
import { useHistory } from 'react-router-dom';
import uuid from '../lib/uuid';
import Settings from '../cms/Settings';

const AddProduct = () => {
  const history = useHistory();

  return <Settings
    icon="/images/iconmonstr-diamond-9.svg"
    label="Add Product"
    invertIcon={true}
    onClick={() => history.push(`/shop/**new**/${uuid()}`)}
  />
};

export { AddProduct as default }