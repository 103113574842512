import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSearch from '../hooks/useSearch';
import Page from '../Page';
import Breadcrumb from '../Breadcrumb';
import TextBanner from '../TextBanner';
import ProductList from '../ProductList';
import Dropdown from '../Dropdown';
import ProductTypes from '../ProductTypes';
import '../../scss/pages/shop.scss';
import Spinner from '../Spinner';
const { parse } = require('query-string');

const sortOptions = {
  'price-low-high': { label: 'Price Low to High', searchBy: 'PRICE', orderBy: 'ASCENDING' },
  'price-high-low': { label: 'Price High to Low', searchBy: 'PRICE', orderBy: 'DESCENDING' },
  'newest-in': { label: 'Newest In', searchBy: 'DATE', orderBy: 'DESCENDING' },
};

const DEFAULT_SORT = 'newest-in';
const PRODUCT_TYPES_ID = 'product-types';
const PAGE_LIMIT = 12;

const createURL = ({ filters, sortBy }) => {
  const query = [];
  if (sortBy !== DEFAULT_SORT) {
    query.push({ key: 'sortBy', value: sortBy });
  }
  return `/shop/${filters.join(',')}${query.length ? `?${query.map(({ key, value }) => `${key}=${value}`).join('&')}` : ''}`;
};

const Shop = () => {
  const location = useLocation();
  const history = useHistory();
  const { filter } = useParams();
  const [sortOpen, setSortOpen] = useState(false);
  const { sortBy = DEFAULT_SORT } = parse(location.search);
  const [activeFilters, setActiveFilters] = useState([]);
  const [{ loading, products = [], run, showMore, complete }, { updateSortBy, updateFilters }] = useSearch({ limit: PAGE_LIMIT, defer: true });
  const [{ productTypes, defaultHeader, defaultSubHeader, mixedHeader, mixedSubHeader }, updateProductTypes] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  useEffect(() => {
    setActiveFilters((filter ?? '').trim().split(',').filter(s => s.trim().length));
  }, [filter]);

  useEffect(() => {
    if (productTypes) {
      const productTypeIds = Object.keys(productTypes)
        .filter(slug => activeFilters.includes(slug))
        .map((slug) => `${PRODUCT_TYPES_ID}#${productTypes[slug].id}`);
      updateFilters(productTypeIds);
    }
    if (activeFilters && productTypes) {
      run();
    }
  }, [activeFilters, productTypes, updateFilters, run]);

  useEffect(() => {
    updateSortBy({ ...sortOptions[sortBy], limit: PAGE_LIMIT });
  }, [sortBy, updateSortBy]);

  useEffect(() => {
    if (productTypes) {
      const validFilters = activeFilters.filter(filter => filter in productTypes);
      if (activeFilters.length !== validFilters.length) {
        history.push(createURL({ filters: validFilters, sortBy }));
      }
    }
  }, [productTypes, history, activeFilters, sortBy]);

  const setSort = (selected) => {
    setSortOpen(false);
    history.push(createURL({ filters: activeFilters, sortBy: selected }));
  };

  const clearFilters = useCallback(() => {
    history.push(createURL({ filters: [], sortBy }));
  }, [history, sortBy]);

  const toggleFilter = useCallback((type) => {
    if (activeFilters.includes(type)) {
      history.push(createURL({ filters: activeFilters.filter(t => t !== type), sortBy }));
    } else {
      history.push(createURL({ filters: [...activeFilters, type], sortBy }));
    }
  }, [history, activeFilters, sortBy]);

  const getBannerText = (activeFilters) => {
    if (activeFilters.length > 1) {
      return {
        header: mixedHeader,
        subHeader: mixedSubHeader,
      };
    }
    if (activeFilters.length === 1 && productTypes) {
      return { ...productTypes[activeFilters[0]] };
    }
    return {
      header: defaultHeader,
      subHeader: defaultSubHeader,
    };
  };

  useEffect(() => {
    if (sortOpen) {
      const onClick = (e) => {
        setSortOpen(false);
      };
      window.addEventListener('click', onClick);
      return () => {
        window.removeEventListener('click', onClick);
      };
    }
  }, [sortOpen]);

  const bannerText = getBannerText(activeFilters);

  return (
    <Page title={`Shop${bannerText.header ? ` - ${bannerText.header}` : ''}`} className="shop-page" description={bannerText.header ? `${bannerText.header}: ${bannerText.subHeader}` : ''}>
      <Breadcrumb />
      <TextBanner {...bannerText} />
      <section className="shop-page__search">
        <ProductTypes id={PRODUCT_TYPES_ID} updateProductTypes={updateProductTypes} activeFilters={activeFilters} toggleFilter={toggleFilter} clearFilters={clearFilters} />
        <section className="shop-page__search__results">
          <section className="shop-page__search__results__options">
            <section className="shop-page__search__results__options__sort">
              <span className="shop-page__search__results__options__sort__label">Sort by</span>
              <button className="shop-page__search__results__options__sort__button" onClick={() => setSortOpen(!sortOpen)}>Sort</button>
              <ul className={`shop-page__search__results__options__sort__items--popup ${ sortOpen ? 'shop-page__search__results__options__sort__items--popup--open' : ''}`}>
                {
                  Object.keys(sortOptions).map((id) => ({ id, ...sortOptions[id] })).map((sort) =>
                    <li
                      key={sort.id}
                      className={`shop-page__search__filters__options__items__item ${sortBy === sort.id ? 'shop-page__search__filters__options__items__item--active' : ''}`}
                      onClick={(e) => setSort(sort.id)}
                    >
                      <span className="shop-page__search__filters__options__items__item__indicator"/>
                      <span className="shop-page__search__filters__options__items__item__label">{sort.label}</span>
                    </li>
                  )
                }
              </ul>
              <Dropdown className="shop-page__search__results__options__sort__items" items={Object.keys(sortOptions).map((id) => ({ id, ...sortOptions[id] }))} selectedId={sortBy} onChange={(selected) => setSort(selected)}/>
            </section>
          </section>
          <ProductList products={products} includeWishlist={true}/>
          { !loading && showMore ? <button className="shop-page__search__results__show-more" onClick={showMore}>Show More Products</button> : null }
          { loading && (!products.length || showMore) ? <Spinner instant={true} dark={true} /> : null }
          { complete && !products?.length ? <p className="shop-page__search__results__empty">No items found. Please try a different filter.</p> : null}
        </section>
      </section>
    </Page>
  );
};

export { Shop as default };