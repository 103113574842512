import React from 'react';

const EditDateTime = ({ label, value, required = false, disabled = false, onChange, error }) => {
  const onInput = (e) => {
    onChange(new Date(e.target.value).toISOString());
  };

  return <div className="cms__edit__content__field-container">
    <label className="cms__edit__content__field-container__label">{label}</label>
    { error ? <p className="cms__edit__content__field-container__error">{error}</p> : null }
    <input
      required={required}
      disabled={disabled}
      type="datetime-local"
      className={`cms__edit__content__field-container__field ${error ? 'cms__edit__content__field-container__field--error' : ''}`}
      value={(value || '').replace(/:\d+(?:\.\d+)?Z$/, '')}
      onChange={onInput}
    />
  </div>;
};

export { EditDateTime as default };