import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import dynamicHookReducer, { FINISH, UPDATE_FETCH_STATE, UPDATE_PREVIEW, UPDATE_REMOVE_STATE, UPDATE_SUBMIT_STATE } from '../reducers/dynamicHookReducer';
import { store } from '../providers/CmsProvider';

import useGraph from './useGraph';
import useHistory from './useHistory';
import useContentCache from './useContentCache';

const useDynamic = ({ id, typename, query, mutation, historyQuery, cacheEnabled = false }) => {
  const { getQuery, getMutation } = useContext(store);
  const [autoLoad, setAutoLoad] = useState(true);
  const [{
    type,
    loading = false,
    error,
    notFound = false,
    data,
    preview,
    submitting = false,
    failure,
    response,
    removing = false,
    removeResponse,
    removeError,
    hasChanged = false,
  }, dispatch] = useReducer(dynamicHookReducer, {
    type: {
      name: typename,
      query: typename.replace(/^(.)/, (char) => char.toLowerCase()),
      mutation: `update${typename}`,
      remove: `delete${typename}`,
    },
  });
  const [cache, updateCache] = useContentCache(`${typename}:${id}`);
  const [fetchState, { request }] = useGraph();
  const [submitState, { request: submit }] = useGraph();
  const [removeState, { request: remove }] = useGraph();
  const [{
    data: history,
    loading: historyLoading,
    error: historyError,
    showMore: historyShowMore,
  }, { request: loadHistory }] = useHistory({ id, type: typename, query: historyQuery || getQuery('history') });

  useEffect(() => dispatch({ type: UPDATE_FETCH_STATE, ...fetchState }), [fetchState]);
  useEffect(() => dispatch({ type: UPDATE_SUBMIT_STATE, ...submitState }), [submitState]);
  useEffect(() => dispatch({ type: UPDATE_REMOVE_STATE, ...removeState }), [removeState]);

  useEffect(() => {
    if (!cache) {
      console.log(`Requesting data for: ${type.name}:${id}`);
      request(query || getQuery(type.query), { id });
    }
  }, [cache, request, query, id, type, getQuery]);

  useEffect(() => {
    if (data || notFound || error || cache) {
      setAutoLoad(false);
    }
  }, [data, notFound, error, cache]);

  useEffect(() => {
    if (cacheEnabled && data) {
      updateCache(data);
    } else if (!cacheEnabled) {
      updateCache(undefined);
    }
  }, [updateCache, cacheEnabled, data]);

  const onSubmit = useCallback((params = {}) => {
    console.log(`Submitting data for: ${type.name}:${id}`);
    submit(mutation || getMutation(type.mutation), params);
  }, [mutation, submit, id, type, getMutation]);

  const onRemove = useCallback(() => {
    console.log(`Removing: ${type.name}:${id}`);
    remove(mutation || getMutation(type.remove), { id });
  }, [mutation, remove, id, type, getMutation]);

  const updatePreview = useCallback((params) => {
    dispatch({ type: UPDATE_PREVIEW, data: { [type.query]: params } });
  }, [type.query]);

  const close = useCallback((params) => {
    dispatch({ type: FINISH, data: params });
  }, []);

  return [
    {
      type,
      loading: autoLoad || loading,
      error,
      notFound,
      data: preview || cache || data,
      submitting,
      failure,
      response,
      removing,
      removeResponse,
      removeError,
      hasChanged,
      historyLoading,
      historyError,
      history,
      historyShowMore,
    },
    {
      submit: onSubmit,
      remove: onRemove,
      updatePreview,
      close,
      loadHistory,
    },
  ];
};

export { useDynamic as default };