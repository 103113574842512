import React, { useCallback, useEffect, useReducer } from 'react';
import shopReducer, { SET_WISHLIST, SET_BASKET } from '../reducers/shopReducer';

const store = React.createContext({});
const { Provider } = store;

const BASKET_ID = '~~basket~~';
const WISHLIST_ID = '~~wishlist~~';
const WISHLIST_MAX = 12;
const BAG_MAX = 20;

const ShopProvider = ({ children }) => {
  const [{ wishlist, basket }, dispatch] = useReducer(shopReducer, {
    wishlist: JSON.parse(localStorage.getItem(WISHLIST_ID) || '[]'),
    basket: JSON.parse(localStorage.getItem(BASKET_ID) || '[]'),
  });

  useEffect(() => {
    const onStorageChange = ({ key, newValue } = {}) => {
      if (key === WISHLIST_ID) {
        dispatch({ type: SET_WISHLIST, wishlist: JSON.parse(newValue || '[]')});
      } else if (key === BASKET_ID) {
        dispatch({ type: SET_BASKET, basket: JSON.parse(newValue || '[]')});
      }
    };
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, [dispatch]);

  const updateWishlist = useCallback((newList) => {
    if (wishlist?.length !== newList.length) {
      const trimmed = newList.reverse().slice(0, WISHLIST_MAX);
      try {
        localStorage.setItem(WISHLIST_ID, JSON.stringify(trimmed));
      } catch (e) {
        console.log('Failed to store wishlist', e);
      }
      dispatch({ type: SET_WISHLIST, wishlist: trimmed });
    }
  }, [wishlist, dispatch]);

  const wishlistAdd = useCallback((productId) => {
    if (!productId) return;

    updateWishlist((wishlist) ? [...new Set([...wishlist, productId])] : [productId]);
  }, [wishlist, updateWishlist]);

  const wishlistRemove = useCallback((productIds) => {
    const deadIds = Array.isArray(productIds) ? productIds : [productIds];
    updateWishlist((wishlist) ? wishlist.filter((id) => !deadIds.includes(id)) : []);
  }, [wishlist, updateWishlist]);

  const wishlistToggle = useCallback((productId) => {
    if (!productId) return;

    if (wishlist?.includes(productId)) {
      wishlistRemove(productId);
    } else {
      wishlistAdd(productId);
    }
  }, [wishlist, wishlistAdd, wishlistRemove]);

  const updateBasket = useCallback((newList) => {
    if (basket?.length !== newList.length) {
      const trimmed = newList.reverse().slice(0, BAG_MAX);
      try {
        localStorage.setItem(BASKET_ID, JSON.stringify(trimmed));
      } catch (e) {
        console.log('Failed to store basket', e);
      }
      dispatch({ type: SET_BASKET, basket: trimmed });
    }
  }, [basket, dispatch]);

  const basketAdd = useCallback((productId) => {
    if (!productId) return;

    updateBasket((basket) ? [...new Set([...basket, productId])] : [productId]);
  }, [basket, updateBasket]);

  const basketRemove = useCallback((productIds) => {
    const deadIds = Array.isArray(productIds) ? productIds : [productIds];
    updateBasket((basket) ? basket.filter((id) => !deadIds.includes(id)) : []);
  }, [basket, updateBasket]);

  const basketClear = useCallback(() => {
    updateBasket([]);
  }, [updateBasket]);

  const basketToggle = useCallback((productId) => {
    if (!productId) return;

    if (basket?.includes(productId)) {
      basketRemove(productId);
    } else {
      basketAdd(productId);
    }
  }, [basket, basketAdd, basketRemove]);

  return <Provider value={{ wishlist, wishlistAdd, wishlistRemove, wishlistToggle, basket, basketAdd, basketRemove, basketToggle, basketClear }}>{children}</Provider>
};

export { ShopProvider as default, store };