import { NavLink } from 'react-router-dom';
import React from 'react';

const Link = ({ to, className, children }) => {
  const isExternalLink = to && !/^\//.test(to);

  return isExternalLink ?
    <a href={to} rel="noopener noreferrer" target="_blank" className={className}>{children}</a>
    :
    <NavLink className={className} to={to}>{children}</NavLink>;
};

export { Link as default };