import React from 'react';
import Gallery, { useGallery } from './Gallery';

const EditImage = ({ label, value, onChange, canRemove = false, error }) => {
  const [showing, { open, close }] = useGallery();

  const onCloseGallery = ([{ url } = {}]) => {
    close();
    if (url) {
      onChange(url);
    }
  };

  const remove = (e) => {
    e.stopPropagation();
    onChange();
  };

  return <div className="cms__edit__content__field-container">
    { label ? <label className="cms__edit__content__field-container__label">{ label }</label> : null }
    { error ? <p className="cms__edit__content__field-container__error">{error}</p> : null }
    <div className={`cms__edit__content__image-container cms__edit__content__image-container--editable ${ error ? 'cms__edit__content__image-container--error' : ''}`} onClick={open}>
      { value ? <img className="cms__edit__content__image-container__image" src={value} alt={value}/> : <span className="cms__edit__content__image-container__empty">Click here select an image</span> }
      { value && canRemove ? <span className="cms__edit__content__image-container__remove" onClick={remove} /> : null }
    </div>
    {showing ? <Gallery multiple={false} onClose={onCloseGallery}/> : null}
  </div>
};

export { EditImage as default };