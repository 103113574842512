import React from 'react';
import { NavLink } from "react-router-dom";
import useWishlist from './hooks/useWishlist';
import useBasket from './hooks/useBasket';
import '../scss/shop-nav.scss';

const MainNav = () => {
  const [wishlist] = useWishlist();
  const [basket] = useBasket();

  return (
    <nav className="shop-nav">
      <ul className="shop-nav__items">
          <li key="wishlist" className="shop-nav__items__item">
            <NavLink to="/wishlist" className="shop-nav__items__item__link shop-nav__items__item__link--wishlist" activeClassName="shop-nav__items__item__link--wishlist--selected">
              { wishlist?.length ? <span className="shop-nav__items__item__link--wishlist__count">{wishlist.length}</span> : null }
            </NavLink>
          </li>
          <li key="bag" className="shop-nav__items__item">
            <NavLink to="/my-bag" className="shop-nav__items__item__link shop-nav__items__item__link--bag" activeClassName="shop-nav__items__item__link--bag--selected">
              { basket?.length ? <span className="shop-nav__items__item__link--bag__count">{basket.length}</span> : null }
            </NavLink>
          </li>
      </ul>
    </nav>
  );
};

export { MainNav as default };