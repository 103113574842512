const updateHero = `mutation($input: HeroInput!) {
  updateHero(input: $input) {
    link 
    image 
    header
    subHeader
    buttonLabel
  }
}`;

const updateSiteBanner = `mutation($input: SiteBannerInput!) {
  updateSiteBanner(input: $input) {
    message
  }
}`;

const updateSidebar = `mutation($input: SidebarInput!) {
  updateSidebar(input: $input) {
    message
  }
}`;

const updateContactSettings = `mutation($input: ContactSettingsInput!) {
  updateContactSettings(input: $input) {
    whatsapp
    phone
    email
  }
}`;

const updateSocialSettings = `mutation($input: SocialSettingsInput!) {
  updateSocialSettings(input: $input) {
    facebookLink
    ebayLink
    instagramLink
  }
}`;

const updateProductTypes = `mutation($input: ProductTypesInput!) {
  updateProductTypes(input: $input) {
    defaultHeader
    defaultSubHeader
    mixedHeader
    mixedSubHeader
    types {
      id
      name
      header
      subHeader
    }
  }
}`;

const updateProduct = `mutation($input: ProductInput!) {
  updateProduct(input: $input) {
    productType {
      id
      name
    }
    header
    description
    price
    images
    hidden
    promoted
  }
}`;

const updateQuickLinks = `mutation($input: QuickLinksInput!) {
  updateQuickLinks(input: $input) {
    links {
      id
      image
      title
      link
    }
  }
}`;

const deleteProduct = `mutation($id: ID!) {
  deleteProduct(id: $id) {
    complete
  }
}`;

const checkoutAllocate = `mutation($input: CheckoutInput!) {
  checkoutAllocate(input: $input) {
    sessionId
    orderId
    status
    items
    totalCost
    offerCode
  }
}`;

const checkoutDeallocate = `mutation($input: CheckoutInput!) {
  checkoutDeallocate(input: $input) {
    sessionId
    orderId
    status
    items
    totalCost
    offerCode
  }
}`;

const checkoutCreate = `mutation($input: CheckoutInput!) {
  checkoutCreate(input: $input) {
    sessionId
    orderId
    status
    items
    totalCost
    offerCode
  }
}`;

const checkoutComplete = `mutation($input: CheckoutCompleteInput!) {
  checkoutComplete(input: $input) {
    sessionId
    orderId
    status
    items
    totalCost
    offerCode
  }
}`;

const token = `mutation($formId: ID) {
  token(formId: $formId) {
    token
  }
}`;

const contactForm = `mutation($input: ContactFormInput!) {
  contactForm(input: $input) {
    successful,
    message
  }
}`;

const newsletter = `mutation($input: NewsletterInput!) {
  newsletter(input: $input) {
    successful,
    message
  }
}`;

const updateOfferCode = `mutation($input: OfferCodeInput!) {
  updateOfferCode(input: $input) {
    code
    validFrom
    validTo
    discountAmount
    discountType
    enabled
    maxClaims
    claims
  }
}`;

const deleteOfferCode = `mutation($code: ID!) {
  deleteOfferCode(code: $code) {
    complete
  }
}`;

const all = {
  updateHero,
  updateSiteBanner,
  updateSidebar,
  updateContactSettings,
  updateSocialSettings,
  updateProductTypes,
  updateProduct,
  updateQuickLinks,
  deleteProduct,
  checkoutAllocate,
  checkoutDeallocate,
  checkoutCreate,
  checkoutComplete,
  token,
  contactForm,
  newsletter,
  updateOfferCode,
  deleteOfferCode,
};

export {
  all as default,
  updateHero,
  updateSiteBanner,
  updateSidebar,
  updateContactSettings,
  updateSocialSettings,
  updateProductTypes,
  updateProduct,
  deleteProduct,
  checkoutAllocate,
  checkoutDeallocate,
  checkoutCreate,
  checkoutComplete,
  updateQuickLinks,
  token,
  contactForm,
  newsletter,
  updateOfferCode,
  deleteOfferCode,
};