import { useCallback, useContext, useEffect, useState } from 'react';
import { store } from '../providers/CmsProvider';
import useGraph from './useGraph';

const queryFromId = (id) => id
  .replace(/^[a-z]/i, (match) => match.toLowerCase())
  .replace(/-[a-z]/gi, (match) => match.replace(/-/g, '').toUpperCase());

const useSettings = (id) => {
  const { getSetting: getCachedSettings, updateSetting, getQuery } = useContext(store);
  const cache = getCachedSettings(id);
  const [{ loading, complete }, { request }] = useGraph();
  const [state, setState] = useState(cache);

  const onUpdate = useCallback((value) => {
    updateSetting({ id, value });
    setState(value);
  }, [updateSetting, id, setState]);

  useEffect(() => {
    setState(getCachedSettings(id));
  }, [cache, id, getCachedSettings]);

  useEffect(() => {
    const loadSettings = async() => {
      if (!loading && !complete && !getCachedSettings(id)) {
        const query = queryFromId(id);
        const { data: { [query]: data } = {} } = await request(getQuery(query), { id }) || {};
        if (data) {
          onUpdate(data);
        }
      }
    };
    loadSettings();
  }, [id, loading, complete, getCachedSettings, onUpdate, getQuery, request]);

  return [state, onUpdate];
};

export { useSettings as default };