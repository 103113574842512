const LOADING = 'LOADING';
const ERROR = 'ERROR';
const SET_FOLDER = 'SET_FOLDER';
const UPLOAD_START = 'UPLOAD_START';
const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE';
const UPLOAD_RESET = 'UPLOAD_RESET';
const TOGGLE_SELECTED = 'TOGGLE_SELECTED';
const REMOVE_SELECTED = 'REMOVE_SELECTED';
const CLEAR_SELECTED = 'CLEAR_SELECTED';
const SET_SORT = 'SET_SORT';

const galleryReducer = (state, action) => {
  const selected = { ...state.selected };

  switch (action.type) {
    case LOADING:
      return { ...state, loading: true, error: undefined };
    case ERROR:
      return { ...state, loading: false, error: action.error };
    case SET_FOLDER:
      return { ...state, loading: false, error: undefined, folder: action.folder };
    case UPLOAD_START:
      return { ...state, uploading: true, uploads: action.uploads, uploadedCount: 0 };
    case UPLOAD_COMPLETE:
      return { ...state, uploadedCount: state.uploadedCount + 1 };
    case UPLOAD_RESET:
      return { ...state, uploading: false, uploads: undefined, uploadedCount: 0 };
    case TOGGLE_SELECTED:
      if (action.image.key in selected) {
        delete selected[action.image.key];
      } else {
        if (!state.multiple || action.quickSelect) {
          Object.keys(selected).forEach((key) => delete selected[key]);
        }
        selected[action.image.key] = action.image;
      }
      return { ...state, selected, quickSelect: !!action.quickSelect, selectedCount: Object.keys(selected).length };
    case REMOVE_SELECTED:
      action.keys.forEach(key => delete selected[key]);
      return { ...state, selected, selectedCount: Object.keys(selected).length };
    case CLEAR_SELECTED:
      return { ...state, selected: {}, selectedCount: 0 };
    case SET_SORT:
      return { ...state, sort: action.sort };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

export {
  galleryReducer as default,
  LOADING,
  ERROR,
  SET_SORT,
  SET_FOLDER,
  TOGGLE_SELECTED,
  CLEAR_SELECTED,
  REMOVE_SELECTED,
  UPLOAD_COMPLETE,
  UPLOAD_RESET,
  UPLOAD_START,
};