import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import useWishlist from '../hooks/useWishlist';
import useBasket from '../hooks/useBasket';
import useGraph from '../cms/hooks/useGraph';
import Page from '../Page';
import TextBanner from '../TextBanner';
import { products as query } from '../../graphql/query';

import '../../scss/pages/wishlist.scss';
import { formatPrice, slugify } from '../lib/formatters';
import Spinner from '../Spinner';
import ErrorNotice from '../ErrorNotice';

const WishlistItem = ({ id, productType, header, images, price, inBasket, onRemove, onBasketAdd, onCheckout }) => {
  return (
    <figure className="wishlist-page__items__item">
      <button className="wishlist-page__items__item__remove" onClick={() => onRemove(id)} />
      <NavLink to={`/shop/${slugify(productType?.name)}/${slugify(header)}-${id}`} className="wishlist-page__items__item__link">
        <div className="wishlist-page__items__item__image-container">
          { images?.length ? <img alt={header} className="wishlist-page__items__item__image" src={images[0]}/> : null }
        </div>
      </NavLink>
      <figcaption className="wishlist-page__items__item__info">
        <span className="wishlist-page__items__item__info__title">{header}</span>
        <span className="wishlist-page__items__item__info__price">{formatPrice(price)}</span>
      </figcaption>
      { inBasket ? <button className="wishlist-page__items__item__buy wishlist-page__items__item__buy--checkout" onClick={() => onCheckout()}>Added To Bag</button> : null }
      { !inBasket ? <button className="wishlist-page__items__item__buy" onClick={() => onBasketAdd(id)}>Add To Bag</button> : null }
    </figure>
  );
};

const Wishlist = () => {
  const [wishlist, { wishlistRemove }] = useWishlist();
  const [basket, { basketAdd }] = useBasket();
  const [{ loading, error, data: { products } = {}, complete }, { request }] = useGraph();
  const [sold, setSold] = useState();
  const history = useHistory();

  useEffect(() => {
    if (wishlist?.length) {
      request(query, { ids: wishlist });
    }
  }, [wishlist, request]);

  useEffect(() => {
    if (complete && wishlist?.length) {
      if (products?.length) {
        const removed = wishlist.filter((id) => !products.find(({ id: productId }) => id === productId));
        setSold(removed);
      } else {
        setSold(wishlist);
      }
    }
  }, [wishlist, products, setSold, complete]);

  useEffect(() => {
    if (sold?.length) {
      wishlistRemove(sold);
    }
  }, [sold, wishlistRemove]);

  const onCheckout = useCallback(() => {
    history.push('/my-bag');
  }, [history]);

  const onRemove = useCallback((id) => {
    wishlistRemove(id);
  }, [wishlistRemove]);

  return (
    <Page title="Wishlist" className="wishlist-page" description="Up to 12 items will be remembered until they are sold. Add any of the items below to your bag.">
      <TextBanner header="Wishlist" subHeader="Up to 12 items will be remembered until they are sold. Add any of the items below to your bag." />
      <section className="wishlist-page__items">
        { loading ? <Spinner center={true} /> : null }
        { error ? <ErrorNotice error={error} /> : null }
        { wishlist?.length && products?.length ?
          products.map(item => <WishlistItem key={item.id} {...item} inBasket={basket?.includes(item.id)} onRemove={onRemove} onBasketAdd={basketAdd} onCheckout={onCheckout} />)
          : <p className="wishlist-page__items__empty">No items currently in your wishlist</p>
        }
      </section>
      <section className="wishlist-page__options">
        <NavLink to="/shop" className="wishlist-page__options__continue">Continue Shopping</NavLink>
      </section>
    </Page>
  );
};

export { Wishlist as default };