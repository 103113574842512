import React from 'react';

const EditField = ({ label, value, disabled = false, multiline = false, onChange, allowed, error }) => {
  const onInput = (e) => {
    if (allowed && !allowed.test(e.target.value)) {
      return onChange(value);
    }
    onChange(e.target.value);
  };

  return <div className="cms__edit__content__field-container">
    <label className="cms__edit__content__field-container__label">{label}</label>
    { error ? <p className="cms__edit__content__field-container__error">{error}</p> : null }
    {multiline ?
      <textarea disabled={disabled} className={`cms__edit__content__field-container__field cms__edit__content__field-container__field--multiline ${error ? 'cms__edit__content__field-container__field--error' : ''}`} value={value} onChange={onInput} />
      :
      <input disabled={disabled} className={`cms__edit__content__field-container__field ${error ? 'cms__edit__content__field-container__field--error' : ''}`} value={value} onChange={onInput}/>
    }
  </div>
};

export { EditField as default };