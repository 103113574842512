import React from 'react';
import MainNav from './MainNav';
import Logo from './Logo';
import ShopNav from './ShopNav';
import SiteBanner from './SiteBanner';

import '../scss/header.scss';

const Header = () => {
  return (
    <header className="header">
      <MainNav />
      <Logo />
      <ShopNav />
      <SiteBanner  id="site-banner" />
    </header>
  );
};

export { Header as default };