import React from 'react';

const slugify = (name) => (name ?? '')
  .toLowerCase()
  .replace(/[^a-z0-9\s]/g, '')
  .trim()
  .replace(/\s+/g, '-');

const formatPrice = (rawPrice) => (rawPrice ? roundNumber(rawPrice) : 0.00).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });

const uncamelCase = (key) => (key ?? '').replace(/[A-Z][^A-Z]/g, (match) => ` ${match}`).replace(/^(.)/, (char) => char.toUpperCase()).trim();

const textToParagraphs = (text) => (text ?? '').split('\n').filter(line => line.length).map((line, index) => <p key={index}>{line}</p>);

const multiplyByTenToThePower = (value, dp) => value + 'e' + dp;
const divideByTenToThePower = (value, dp) => value + 'e-' + dp;
const roundNumber = (value, dp = 2) => isNaN(value) ? value : Number(divideByTenToThePower(Math.round(multiplyByTenToThePower(value, dp)), dp));

export { slugify, formatPrice, uncamelCase, textToParagraphs, roundNumber };