import React, { useCallback, useEffect, useState } from 'react';

const ConfirmButton = ({ className, disabled = false, children, onClick, ...rest }) => {
  const delay = 2;
  const [pressed, setPressed] = useState(false);
  const [remaining, setRemaining] = useState(delay);

  const reset = useCallback(() => {
    setPressed(false);
    setRemaining(delay);
  }, [delay]);

  useEffect(() => {
    if (pressed && remaining > 0) {
      const timer = setTimeout(() => {
        setRemaining((old) => old - 1)
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [pressed, remaining]);

  useEffect(() => {
    if (pressed && remaining === 0) {
        reset();
        if (onClick) onClick();
    }
  }, [pressed, remaining, onClick, reset]);

  return <button
    disabled={disabled}
    onBlur={reset}
    onMouseDown={(e) => {
      if (e.button === 0) {
        setPressed(true);
      }
    }}
    onMouseUp={reset}
    className={`cms__confirm-button ${pressed ? 'cms__confirm-button--active' : ''} ${className || ''}`}
    {...rest}
  >
    <span className="cms__confirm-button__content">{children}</span>
  </button>;
};

export { ConfirmButton as default };