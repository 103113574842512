import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { createPortal } from 'react-dom';
import dynamicReducer, { START_EDIT, STOP_EDIT, TOGGLE_HISTORY } from './reducers/dynamicReducer';
import useDynamic from './hooks/useDynamic';
import useSettings from './hooks/useSettings';
import EditPanel from './EditPanel';
import ConfirmButton from './ConfirmButton';

const SettingsButton = ({ label, icon, className, invertIcon = false, disabled = false, onClick, confirmation }) => {
  const renderButton = (children) => confirmation ?
    <ConfirmButton disabled={disabled} onClick={onClick} className={`cms__settings__content__button cms__settings__content__button ${className || ''}`} title={label}>{children}</ConfirmButton> :
    <button disabled={disabled} onClick={onClick} className={`cms__settings__content__button cms__settings__content__button ${className || ''}`} title={label}>{children}</button>;

  return renderButton(<>
    {icon ? <img className={`cms__settings__content__button__icon ${invertIcon ? 'cms__settings__content__button__icon--invert' : ''}`} src={icon} alt={label}/> : null}
    {!icon && label ? <span className="cms__settings__content__button__label">{label}</span> : null}
  </>);
};

const SettingsButtonWithPanel = ({ id, label, icon, invertIcon = false, typename, panel: Edit, query, mutation, historyQuery, confirmation, cachedEnabled = false }) => {
  const [, updateCache] = useSettings(id);
  const [{ editing = false, historyOpen = false }, dispatch] = useReducer(dynamicReducer, {});
  const [
    {
      loading,
      error,
      data,
      submitting,
      failure,
      response,
      hasChanged,
      historyLoading,
      historyError,
      history,
    },
    {
      submit,
      updatePreview,
      close,
      loadHistory,
    },
  ] = useDynamic({ id, typename, query, mutation, historyQuery, cachedEnabled });
  const [validator, setValidator] = useState();
  const [invalid, setInvalid] = useState();
  const toggleHistory = () => dispatch({ type: TOGGLE_HISTORY });

  const onEdit = (e) => {
    if (!editing) {
      e.preventDefault();
      dispatch({ type: START_EDIT });
    }
  };

  const onClose = useCallback((data) => {
    dispatch({ type: STOP_EDIT });
    close(data);
    setInvalid(undefined);
  }, [close, setInvalid, dispatch]);

  useEffect(() => {
    if (historyOpen) {
      loadHistory();
    }
  }, [historyOpen, loadHistory]);

  useEffect(() => {
    if (!submitting && !error && response) {
      onClose(response);
    }
  }, [submitting, response, error, onClose]);

  useEffect(() => {
    updateCache(data);
  }, [updateCache, data]);

  const onSetValidator = useCallback((validator) => {
    setValidator(() => validator);
  }, [setValidator]);

  return <>
    <SettingsButton label={label} icon={icon} invertIcon={invertIcon} onClick={onEdit} confirmation={confirmation}/>
    {editing ?
      createPortal(
        <EditPanel
          header={label}
          id={id}
          data={data}
          loading={loading}
          submitting={submitting}
          error={failure}
          response={response}
          hasChanged={hasChanged}
          close={onClose}
          submit={submit}
          historyOpen={historyOpen}
          historyLoading={historyLoading}
          historyError={historyError}
          history={history}
          updatePreview={updatePreview}
          toggleHistory={toggleHistory}
          variant="settings"
          validator={validator}
          invalid={invalid}
          setInvalid={setInvalid}
        >
          <Edit id={id} data={data} invalid={invalid} updatePreview={updatePreview} setValidator={onSetValidator} />
        </EditPanel>
        , document.body,
      )
      : null}
  </>;
};

const Settings = (
  {
    id,
    typename,
    label,
    icon,
    invertIcon = false,
    onClick,
    panel,
    query,
    mutation,
    historyQuery,
    confirmation = false,
    cachedEnabled = false,
  },
) => panel ?
  <SettingsButtonWithPanel
    id={id}
    label={label}
    icon={icon}
    invertIcon={invertIcon}
    panel={panel}
    typename={typename}
    query={query}
    mutation={mutation}
    historyQuery={historyQuery}
    confirmation={confirmation}
    cachedEnabled={cachedEnabled}
  />
  :
  <SettingsButton
    id={id}
    label={label}
    icon={icon}
    invertIcon={invertIcon}
    onClick={onClick}
    confirmation={confirmation}
  />;

export { Settings as default, SettingsButton };