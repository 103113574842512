import React, { useCallback, useEffect, useState } from 'react';
import Settings from '../cms/Settings';
import EditField from '../cms/EditField';
import EditList from '../cms/EditList';
import uuid from '../lib/uuid';
import { createPortal } from 'react-dom';
import EditSettingsPanel from '../cms/EditSettingsPanel';
import useGraph from '../cms/hooks/useGraph';

import { offerCodes } from '../../graphql/query';
import { updateOfferCode, deleteOfferCode } from '../../graphql/mutation';
import EditCheckbox from '../cms/EditCheckbox';
import EditDateTime from '../cms/EditDateTime';

const discountType = [
  {id: 'PERCENTAGE', name: 'Percentage' },
  {id: 'FIXED', name: 'Fixed' },
];

const OrderCodesPanel = ({ onClose }) => {
  const [selectedId, setSelectedId] = useState();
  const [selected, setSelected] = useState();
  const [invalid, setInvalid] = useState();
  const [error, setError] = useState();
  const [items, setItems] = useState([]);
  const [list, setList] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [, { request }] = useGraph();

  useEffect(() => {
    const fetchOfferCodes = async () => {
      const { error, data: { offerCodes: { items } = {} } = {} } = await request(offerCodes, { where: {} });
      if (error) {
        setError(error);
      } else {
        setItems(items);
      }
    };
    fetchOfferCodes();
  }, [request, setItems, setError]);

  const onSelect = (id) => {
    if (id === 'add') {
      const code = `OFFER-${uuid().toUpperCase()}`;
      setItems([...items, { code, isNew: true }]);
      setSelectedId(code);
      setHasChanged(true);
    } else {
      setSelectedId(id);
    }
  };

  const onDelete = async (deleteId) => {
    const deleted = items.find(({ id }) => id !== deleteId);
    if (deleted) {
      if (deleted.isNew) {
        setItems(items.filter((item) => item !== deleted));
        setSelectedId(undefined);
        return;
      }

      const { error, data: { deleteOfferCode: { complete } = {} } = {} } = await request(deleteOfferCode, {
        code: deleted.code,
      });
      if (error || !complete) {
        setError(error || 'Failed to delete offer code');
      } else {
        setItems(items.filter((item) => item !== deleted));
      }
    }
  };

  useEffect(() => {
    setList([...items.map(({ code, newCode }) => ({ id: code, name: (newCode ?? code) || '<BLANK-CODE>' })), { id: 'add', name: '+ Add new code' }]);
  }, [setList, items]);

  useEffect(() => {
    setSelected(items.find(({ code }) => code === selectedId));
  }, [selectedId, items, setSelected]);

  const onSubmit = useCallback(async () => {
    const errors = {}
    items.forEach(({ code, newCode, validFrom, validTo, discountType, discountAmount, maxClaims }) => {
      if ((!code || !(code ?? '').trim().length) || (typeof newCode === 'string' && !(newCode ?? '').trim().length)) {
        errors[code] = errors[code] || {};
        errors[code].code = 'Please enter a code';
      }
      if (!(validFrom ?? '').trim().length) {
        errors[code] = errors[code] || {};
        errors[code].validFrom = 'Please enter a valid from date and time';
      }
      if (!(validTo ?? '').trim().length) {
        errors[code] = errors[code] || {};
        errors[code].validTo = 'Please enter a valid to date and time';
      }
      if (!errors?.[code]?.validFrom && !errors?.[code]?.validTo && new Date(validTo).getTime() <= new Date(validFrom).getTime()) {
        errors[code] = errors[code] || {};
        errors[code].validTo = 'The valid to must be a date and time after the valid from';
      }
      if (!discountType?.length) {
        errors[code] = errors[code] || {};
        errors[code].discountType = 'Please select the discount type';
      }
      if (!/^\d+$/.test(discountAmount) || parseInt(discountAmount, 10) <= 0) {
        errors[code] = errors[code] || {};
        errors[code].discountAmount = 'Please enter a discount amount that is greater than 0';
      }
      if (!/^\d+$/.test(maxClaims) || parseInt(maxClaims, 10) <= 0) {
        errors[code] = errors[code] || {};
        errors[code].maxClaims = 'Please enter a max claims value that is greater than 0';
      }
    });
    if (Object.keys(errors).length) {
      setInvalid(errors);
      return;
    }

    try {
      const changed = items.filter(({ changed }) => changed);
      if (changed) {
        await Promise.all(changed.map(async ({ newCode, code, isNew, changed, claims, enabled, ...rest }) => {
          const { error } = await request(updateOfferCode, {
            input: {
              ...rest,
              code: newCode || code,
              enabled: !!enabled,
            }
          });
          if (error) throw new Error(error);
        }));
      }
      setInvalid(undefined);
      onClose();
    } catch (e) {
      setError(e.message);
    }
  }, [items, onClose, setError, request, setInvalid]);

  const updateSelected = (values) => {
    const newItems = [...items.map(item => ({ ...item }))];
    const item = newItems.find(({ code }) => code === selectedId);
    if (item) {
      Object.keys(values).forEach(key => item[key] = values[key]);
      item.changed = true;
      setItems(newItems);
      setHasChanged(true);
    }
  };

  return <EditSettingsPanel header="Offer Codes" close={onClose} error={error ? error : Object.keys(invalid ?? {}).length ? 'Please correct the highlighted problems below and try again.' : ''} hasChanged={hasChanged} submitChanges={onSubmit}>
  <EditList error={Object.keys(invalid ?? {}).length ? 'Please check the items in the list' : ''} invalid={list?.map(({ id }) => id).filter((id) => invalid && id in invalid)} showDelete={true} deleteEnabled={!!selected} label="Code:" items={list} selectedId={selectedId} onChange={onSelect} onDelete={onDelete} />
  { selected ?
    <>
      <EditField disabled={!selected?.isNew} allowed={/^[a-z0-9-]*$/i} label="Code:" value={selected.newCode ?? selected.code ?? ''} error={invalid?.[selectedId]?.code} onChange={(code) => updateSelected({ newCode: code })} />
      <EditDateTime label="Valid From:" value={selected.validFrom ?? ''} error={invalid?.[selectedId]?.validFrom} onChange={(validFrom) => updateSelected({ validFrom })} />
      <EditDateTime label="Valid To:" value={selected.validTo ?? ''} error={invalid?.[selectedId]?.validTo} onChange={(validTo) => updateSelected({ validTo })} />
      <EditList error={invalid?.[selectedId]?.discountType} label="Discount Type:" items={discountType} selectedId={selected.discountType ?? ''} onChange={(discountType) => updateSelected({ discountType })} />
      <EditField allowed={/^\d*$/i} label="Discount Amount:" value={selected.discountAmount ?? ''} error={invalid?.[selectedId]?.discountAmount} onChange={(discountAmount) => updateSelected({ discountAmount })} />
      <EditField allowed={/^\d*$/i} label="Max Claims:" value={selected.maxClaims ?? ''} error={invalid?.[selectedId]?.maxClaims} onChange={(maxClaims) => updateSelected({ maxClaims })} />
      <EditCheckbox label="Enabled" value={selected.enabled ?? false} onChange={(enabled) => updateSelected({ enabled })} />
    </>
    : null }
  </EditSettingsPanel>;
};

const OrderCodes = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  return<>
    <Settings
      id="offer-codes"
      icon="/images/iconmonstr-christmas-42.svg"
      label="Offer Codes"
      invertIcon={true}
      onClick={() => setOpen(!open)}
    />
    { open ? createPortal(<OrderCodesPanel onClose={onClose} />, document.body) : null }
  </>
};

export { OrderCodes as default }