import React, { useState, useEffect } from 'react';
import '../scss/dropdown.scss';

const Dropdown = ({ items, selectedId, className = '', onChange }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(selectedId);
  const { label: currentName = 'Please select...' } = (items.find(({ id } = {}) => selectedId === id) || {});

  const onSelect = (e, id) => {
    e.preventDefault();
    if (id !== selected) {
      setSelected(id);
      if (onChange) {
        onChange(id);
      }
    }
  };

  const toggleOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      const onKeyDown = (e) => {
        if (e.key === 'Escape') {
          setOpen(false);
        }
      };
      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      const onClick = (e) => {
        setOpen(false);
      };
      window.addEventListener('click', onClick);
      return () => {
        window.removeEventListener('click', onClick);
      };
    }
  }, [open]);

  return (
    <div onClick={toggleOpen} className={`${className} dropdown ${open ? 'dropdown--open' : ''}`}>
      <span className="dropdown__selected">{currentName}</span>
      { open ?
        <ul className="dropdown__items">
          {
            items.map(({ id, label } = {}) => (
              <li key={id} onClick={(e) => onSelect(e, id)} className="dropdown__items__item">{label}</li>
            ))
          }
        </ul>
        : null
      }
    </div>
  );
};

export { Dropdown as default };