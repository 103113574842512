import React, { useEffect, useRef, useState } from 'react';
import '../scss/image-preview.scss';

const ImagePreview = ({ images, defaultId, className = '' }) => {
  const thumbnails = useRef();
  const firstImage = useRef();
  const [selected, setSelected] = useState(defaultId);
  const [{ back, next }, setShowButtons] = useState({ back: false, next: false });

  useEffect(() => {
    const element = thumbnails.current;
    if (element) {
      if (element.scrollWidth !== element.clientWidth) {
        const updateState = ({ scrollLeft = 0, scrollWidth = 0, clientWidth = 0 } = {}) => {
          setShowButtons({ back: scrollLeft > 0, next: scrollLeft !== scrollWidth - clientWidth });
        };

        const onScroll = (e) => {
          updateState(e.target);
        };

        updateState(element);
        element.addEventListener('scroll', onScroll);
        return () => {
          element.removeEventListener('scroll', onScroll);
        };
      }
    }
  }, [images, thumbnails, setShowButtons]);

  useEffect(() => {
    if (images) {
      if ((selected && !images.includes(selected)) || !selected) {
        setSelected(images.length ? images[0] : undefined);
      }
    }
  }, [images, selected, setSelected]);

  const scrollThumbnails = (backwards) => {
    if (thumbnails.current) {
      const diff = parseInt(window.getComputedStyle(firstImage.current).width, 10);
      thumbnails.current.scrollTo(backwards ? thumbnails.current.scrollLeft - diff : thumbnails.current.scrollLeft + diff, 0);
    }
  };

  return (
    <div className={`image-preview ${className}`}>
      { selected ? <div className="image-preview__selected">
        <img className="image-preview__selected__image" src={selected} alt={selected} />
      </div> : null }
      <div className={`image-preview__thumbnails ${ images?.length <= 1 ? 'image-preview__thumbnails--hidden' : ''}`}>
        { back ? <button className="image-preview__thumbnails__back" onClick={() => scrollThumbnails(true)} /> : null }
        <ul className="image-preview__thumbnails__items" ref={thumbnails}>
          { images?.map((src, index) =>
            <li ref={index === 0 ? firstImage : null} key={src} className={`image-preview__thumbnails__items__item ${ (!selected && index === 0) || selected === src ? 'image-preview__thumbnails__items__item--selected' : '' }`} onClick={() => setSelected(src)}>
              <img className="image-preview__thumbnails__items__item__image" src={src} alt={src} />
            </li>
          )}
        </ul>
        { next ? <button className="image-preview__thumbnails__next" onClick={() => scrollThumbnails(false)} /> : null }
      </div>
    </div>
  );
};

export { ImagePreview as default }