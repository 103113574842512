import React from 'react';
import Newsletter from './Newletter';
import Sidebar from './Sidebar';
import Link from './Link';
import useSettings from './cms/hooks/useSettings';

import '../scss/footer.scss';

const Footer = () => {
  const [{ whatsapp, phone, email } = {}] = useSettings('contact-settings');
  const [{ facebookLink, ebayLink, instagramLink } = {}] = useSettings('social-settings');

  return (
    <footer className="footer">
      <nav className="footer-nav">
        <h4 className="footer-nav__header">Customer Service</h4>
        <ul className="footer-nav__items">
          <li className="footer-nav__items__item">
            <Sidebar id="returns-policy" className="footer-nav__items__item__link" header="Returns Policy"/>
          </li>
          <li className="footer-nav__items__item">
            <Sidebar id="quality-checks" className="footer-nav__items__item__link" header="Quality Checks"/>
          </li>
          <li className="footer-nav__items__item">
            <Sidebar id="sizing" className="footer-nav__items__item__link" header="Sizing"/>
          </li>
          <li className="footer-nav__items__item">
            <Sidebar id="shipping" className="footer-nav__items__item__link" header="Shipping"/>
          </li>
          <li className="footer-nav__items__item">
            <Sidebar id="privacy" className="footer-nav__items__item__link" header="Privacy Policy"/>
          </li>
        </ul>
      </nav>
      { instagramLink || facebookLink || ebayLink ? <nav className="footer-nav">
        <h4 className="footer-nav__header">Follow Me</h4>
        <ul className="footer-nav__items">
          { instagramLink ? <li className="footer-nav__items__item">
            <Link to={instagramLink} className="footer-nav__items__item__link">Instagram</Link>
          </li> : null }
          { facebookLink ? <li className="footer-nav__items__item">
            <Link to={facebookLink} className="footer-nav__items__item__link">Facebook</Link>
          </li> : null }
          { ebayLink ? <li className="footer-nav__items__item">
            <Link to={ebayLink} className="footer-nav__items__item__link">eBay</Link>
          </li> : null }
        </ul>
      </nav> : null }
      { whatsapp || phone || email ? <nav className="footer-nav">
        <h4 className="footer-nav__header">Let's Talk!</h4>
        <ul className="footer-nav__items">
          { whatsapp ? <li className="footer-nav__items__item">
            <Link to={`https://wa.me/${whatsapp.replace(/[^+0-9]/g, '')}`} className="footer-nav__items__item__link">WhatsApp Me</Link>
          </li> : null }
          { phone ? <li className="footer-nav__items__item">
            <Link to={`tel:${phone.replace(/[^+0-9]/g, '')}`} className="footer-nav__items__item__link">{phone}</Link>
          </li> : null }
          { email ? <li className="footer-nav__items__item">
            <Link to={`mailto:${email}`} className="footer-nav__items__item__link">{email}</Link>
          </li> : null }
        </ul>
      </nav> : null }
      <Newsletter />
    </footer>
  );
};

export { Footer as default };