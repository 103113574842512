import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Dynamic from './cms/Dynamic';
import EditField from './cms/EditField';
import EditImage from './cms/EditImage';
import Link from './Link';
import '../scss/hero.scss';
import Spinner from './Spinner';
import ErrorNotice from './ErrorNotice';

const EditHero = ({ data = {}, invalid, updatePreview, setValidator }) => {
  useEffect(() => {
    setValidator((data = {}) => {
      const errors = {};
      if (!(data?.link ?? '').trim().length || !/^\/|^https?:\/\/.+/.test(data.link)) {
        errors.link = 'Please enter a valid link like /shop or https://google.com';
      }
      return errors;
    });
  }, [setValidator]);

  return <>
    <EditImage label="Image:" value={data.image ?? ''} canRemove={true} onChange={(image) => updatePreview({ ...data, image })}/>
    <EditField label="Header:" value={data.header ?? ''} onChange={(header) => updatePreview({ ...data, header })}/>
    <EditField label="Sub Header:" value={data.subHeader ?? ''} onChange={(subHeader) => updatePreview({ ...data, subHeader })}/>
    <EditField label="Link:" value={data.link ?? ''} error={invalid?.link} onChange={(link) => updatePreview({ ...data, link })}/>
    <EditField label="Button Label:" value={data.buttonLabel ?? ''} onChange={(buttonLabel) => updatePreview({ ...data, buttonLabel })}/>
  </>;
};

const Hero = ({ loading, error, data: { link, image, header, subHeader, buttonLabel } = {} }) => {
  return (
    <figure className="hero">
      {loading ? <Spinner /> : null}
      {error ? <ErrorNotice error={error} /> : null}
      { image && link ?
        <Link className="hero__link" to={link}>
          <img className="hero__image" src={image} alt={header || ''}/>
        </Link>
        : null }
      { image && !link ? <img className="hero__image" src={image} alt={header || ''}/> : null }
      {header ? <h2 className="hero__header">{header}</h2> : null}
      {subHeader ? <p className="hero__sub-header">{subHeader}</p> : null}
      {buttonLabel && link ? <NavLink to={link} className="hero__button">{buttonLabel}</NavLink> : null}
    </figure>
  );
};

const DynamicHero = ({ id }) => <Dynamic id={id} typename="Hero" cacheEnabled={true} component={Hero} editComponent={EditHero} />;

export { DynamicHero as default };