import React from 'react';
import '../scss/error-notice.scss';

const ErrorNotice = ({ error } = {}) => {
  if (error) {
    console.log(error);
    return <div className="error-notice">Oops! Sorry but this content is not currently available</div>
  }
}

export { ErrorNotice as default };