import React, { useCallback, useEffect, useRef, useState } from 'react';
import useBasket from '../hooks/useBasket';
import usePayPal from '../hooks/usePayPal';
import Page from '../Page';
import TextBanner from '../TextBanner';
import Spinner from '../Spinner';
import { NavLink } from 'react-router-dom';
import '../../scss/pages/my-bag.scss';
import { formatPrice, roundNumber, slugify } from '../lib/formatters';
import useGraph from '../cms/hooks/useGraph';
import { products as query, offerCode as offerCodeQuery } from '../../graphql/query';
import { checkoutAllocate, checkoutComplete, checkoutCreate, checkoutDeallocate } from '../../graphql/mutation';
import ErrorNotice from '../ErrorNotice';

const notices = {
  ERROR: 'There was a problem during checkout. Please try again',
  NO_SHIPPING: 'Sorry but we can only delivery to the UK at the moment',
  PRICE_CHANGE: 'The total cost has changed. Please check and try again',
  PRODUCT_CHANGE: 'Sorry but some items are no longer available. Please check and try again',
  EMPTY: 'Please add some items to your bag and try again',
  ALLOCATED: 'Your items have been allocated. Please complete the checkout',
  INCOMPLETE: 'Your items have been allocated. Please complete the checkout',
  APPROVING: 'Your order is being finalised. Please wait...',
  COMPLETE: 'Thank you for your order. You have successfully checked out!',
  OFFER_CODE_INVALID: 'Sorry but the offer code you entered is not valid. Please remove to continue.',
  OFFER_CODE_EXPIRED: 'Sorry but the offer code you entered has expired. Please remove to continue.',
  OFFER_CODE_ERROR: 'There was a problem loading the offer code. Please try again',
  OFFER_CODE_USED: 'Sorry but you can only use the offer code for one purchase. Please remove to continue.'
};

const MIN_OFFER_CODE_LENGTH = 5;

const BagItem = ({ id, productType, header, images, price, onRemove }) => {
  return (
    <section className="my-bag-page__items__item">
      <NavLink to={`/shop/${slugify(productType?.name)}/${slugify(header)}-${id}`} className="my-bag-page__items__item__link">
        { images?.length? <img alt={header} className="my-bag-page__items__item__image" src={images[0]}/> : null }
      </NavLink>
      <div className="my-bag-page__items__item__info">
        <span className="my-bag-page__items__item__info__title">{header}</span>
        <span className="my-bag-page__items__item__info__price">{formatPrice(price)}</span>
      </div>
      <div className="my-bag-page__items__item__remove">
        <button className="my-bag-page__items__item__remove__button" onClick={() => onRemove(id)} />
      </div>
    </section>
  );
};

const MyBag = () => {
  const buttonRef = useRef();
  const [basket, { basketRemove, basketClear, getSession, setSession, deleteSession }] = useBasket();
  const [paypal, { loadPayPal }] = usePayPal();
  const [, { request: checkout }] = useGraph();
  const [{ items, totalCost }, setItems] = useState({});
  const [discount, setDiscount] = useState(0);
  const [buyButton, setBuyButton] = useState();
  const [sold, setSold] = useState();
  const [orderId, setOrderId] = useState();
  const [offerCode, setOfferCode] = useState();
  const [checkoutStatus, setCheckoutStatus] = useState();
  const [{ loading, error, data: { products } = {}, complete }, { request }] = useGraph();
  const [{ error: offerCodeError, data: { offerCode: offerInfo } = {}, complete: offerComplete, loading: offerLoading }, { request: getOfferCode }] = useGraph();
  const [paymentMethod, setPaymentMethod] = useState();
  const [offerCodeInfo, setOfferCodeInfo] = useState()

  useEffect(() => {
    if (basket?.length) {
      request(query, { ids: basket });
    } else {
      setItems({ items: [], totalCost: 0 });
    }
  }, [basket, request, setItems]);

  useEffect(() => {
    if (!offerLoading && offerComplete) {
      setOfferCodeInfo(offerInfo ?? undefined);
    }
  }, [setOfferCodeInfo, offerLoading, offerComplete, offerInfo]);

  useEffect(() => {
    if (offerCode?.trim().length) {
      const getInfo = setTimeout(() => {
        const code = offerCode?.trim() || '';
        if (code.length >= MIN_OFFER_CODE_LENGTH) {
          setCheckoutStatus(undefined);
          getOfferCode(offerCodeQuery, { code });
        } else if (code.length) {
          setCheckoutStatus('OFFER_CODE_INVALID');
          setOfferCodeInfo(undefined);
        }
      }, 500);
      return () => {
        clearTimeout(getInfo);
      };
    } else {
      setCheckoutStatus(undefined);
      setOfferCodeInfo(undefined);
    }
  }, [offerCode, setCheckoutStatus, getOfferCode, setOfferCodeInfo]);

  useEffect(() => {
    if (offerCodeInfo && offerCodeInfo.status !== 'OFFER_CODE_VALID') {
      setCheckoutStatus(offerCodeInfo.status);
    } else if (offerCodeError) {
      setCheckoutStatus('OFFER_CODE_ERROR');
    }
  }, [offerCodeInfo, setCheckoutStatus, offerCodeError]);

  useEffect(() => {
    if (totalCost && offerCode?.length >= MIN_OFFER_CODE_LENGTH && offerCodeInfo?.status === 'OFFER_CODE_VALID' && offerCodeInfo?.details && offerCodeInfo?.details?.discountAmount) {
      if (offerCodeInfo.details.discountType === 'PERCENTAGE') {
        setDiscount(roundNumber((totalCost / 100) * offerCodeInfo.details.discountAmount));
      } else if (offerCodeInfo.details.discountType === 'FIXED') {
        setDiscount(offerCodeInfo.details.discountAmount);
      }
    } else {
      setDiscount(0);
    }
  }, [totalCost, offerCode, offerCodeInfo])

  useEffect(() => {
    if (complete) {
      if (products?.length) {
        setItems({ items: products, totalCost: products.reduce((sum, { price }) => price ? sum + price : sum, 0) });
      } else {
        setItems((old) => old?.length ? { items: [], totalCost: 0 } : old);
      }
    }
  }, [products, complete, setItems]);

  useEffect(() => {
    if (items?.length && !paypal) {
      loadPayPal();
    }
  }, [items, paypal, loadPayPal]);

  useEffect(() => {
    if (!items?.length && buyButton) {
      buyButton.close();
      setBuyButton(undefined);
    }
  }, [items, buyButton]);

  useEffect(() => {
    if (complete) {
      if (products?.length) {
        const removed = basket.filter((id) => !products.find(({ id: productId }) => id === productId));
        if (removed.length) {
          setCheckoutStatus('PRODUCT_CHANGE');
        }
        setSold(removed);
      } else {
        if (basket.length) {
          setCheckoutStatus('PRODUCT_CHANGE');
        }
        setSold(basket);
      }
    }
  }, [basket, products, setSold, setCheckoutStatus, complete]);

  useEffect(() => {
    if (sold?.length) {
      basketRemove(sold);
    }
  }, [sold, basketRemove]);

  useEffect(() => {
    if (checkoutStatus) {
      window.scrollTo(0, 0);
    }
  }, [checkoutStatus, request]);

  useEffect(() => {
    if (orderId) {
      setCheckoutStatus('COMPLETE');
      basketClear();
    }
  }, [orderId, basketClear]);

  const createButton = useCallback(({ paypal, items, totalCost, offerCode, onError }) => {
    const updateSold = (returnItems) => {
      setSold(returnItems ? items.map(({ id }) => id).filter((id) => !returnItems.includes(id)) : []);
    };

    const onFailure = ({ sessionId, status, returnedItems = false }) => {
      button.close();
      setSession(sessionId);
      if (returnedItems !== false) {
        updateSold(returnedItems);
      }

      if (notices[status]) {
        console.log('Failed to create order due to:', status);
        setCheckoutStatus(status);
      } else {
        setCheckoutStatus(undefined);
      }

      setPaymentMethod(undefined);

      if (onError) {
        onError();
      }
    };

    const button = paypal.Buttons({
      style: {
        layout: 'vertical',
        tagline: false,
        label: 'buynow',
      },
      onClick: async (data, actions) => {
        const { fundingSource } = data;
        setPaymentMethod(fundingSource);
        setCheckoutStatus(undefined);
        const { data: { checkoutAllocate: response } = {}, error } = await checkout(checkoutAllocate, {
          input: {
            sessionId: getSession(),
            items: items.map(({ id }) => id),
            totalCost,
            offerCode,
          },
        });

        const status = response?.status || 'ERROR';
        const sessionId = error ? getSession() : response?.sessionId;
        switch (status) {
          case 'ALLOCATED':
            if (sessionId) {
              setSession(sessionId);
              setCheckoutStatus('ALLOCATED');
              return actions.resolve();
            }
            break;
          case 'ERROR':
            onFailure({ sessionId, status });
            break;
          default:
            onFailure({ sessionId, status, returnedItems: response?.items });
            break;
        }
        return actions.reject();
      },
      onCancel: async () => {
        setPaymentMethod(undefined);
        setCheckoutStatus(undefined);
        await checkout(checkoutDeallocate, {
          input: {
            sessionId: getSession(),
            items: items.map(({ id }) => id),
            totalCost,
            offerCode,
          },
        });
      },
      createOrder: async () => {
        const { data: { checkoutCreate: response } = {}, error } = await checkout(checkoutCreate, {
          input: {
            sessionId: getSession(),
            items: items.map(({ id }) => id),
            totalCost,
            offerCode,
          },
        });

        const status = response?.status || 'ERROR';
        const sessionId = error ? getSession() : response?.sessionId;
        switch (status) {
          case 'INCOMPLETE':
            if (sessionId) {
              setCheckoutStatus('INCOMPLETE');
              return response?.orderId;
            }
            return;
          case 'ERROR':
            onFailure({ sessionId, status });
            return;
          default:
            onFailure({ sessionId, status, returnedItems: response?.items });
            return;
        }
      },
      onApprove: async ({ orderID: orderId } = {}, actions) => {
        if (!orderId) {
          return actions.restart();
        }

        setCheckoutStatus('APPROVING');

        const { data: { checkoutComplete: response } = {}, error } = await checkout(checkoutComplete, {
          input: {
            sessionId: getSession(),
            orderId,
            items: items.map(({ id }) => id),
            totalCost,
            offerCode,
          },
        });

        const status = response?.status || 'ERROR';
        const sessionId = error ? getSession() : response?.sessionId;
        switch (status) {
          case 'COMPLETE':
            setOrderId(response?.orderId);
            deleteSession();
            button.close();
            break;
          case 'ERROR':
            onFailure({ sessionId, status });
            return;
          default:
            onFailure({ sessionId, status, returnedItems: response?.items });
            return;
        }
      },
    });
    return button;
  }, [setCheckoutStatus, getSession, setSession, deleteSession, setSold, checkout, setOrderId, setPaymentMethod]);

  useEffect(() => {
    if (paypal && items?.length) {
      const addButton = () => {
        setBuyButton((oldButton) => {
          if (oldButton) {
            oldButton.close();
          }
          const button = createButton({ paypal, items, totalCost: roundNumber(Math.max(0.00, totalCost - discount)), offerCode: offerCodeInfo?.details?.code, onError: addButton });
          button.render('#paypal-checkout-button').catch(e => {});
          return button;
        });
      };
      addButton();
    }
  }, [items, paypal, buttonRef, totalCost, discount, offerCodeInfo, createButton]);

  return (
    <Page title="My Bag" className="my-bag-page" description="Quick don't miss out on the items you have selected">
      {checkoutStatus && notices[checkoutStatus] ?
        <TextBanner header="My Bag" subHeader={notices[checkoutStatus]} notice={!['ALLOCATED', 'INCOMPLETE', 'APPROVING', 'COMPLETE'].includes(checkoutStatus)}/>
        :
        <TextBanner header="My Bag" subHeader="Quick don't miss out on the items you have selected"/>
      }
      <div className="my-bag-page__checkout">
        <div className="my-bag-page__items">
          { loading ? <Spinner instant={true} /> : null }
          { error ? <ErrorNotice error={error} /> : null }
          {!orderId && items?.length ?
            <>
              <section className="my-bag-page__items__headers">
                <span className="my-bag-page__items__headers__image">Item(s)</span>
                <span className="my-bag-page__items__headers__info">Product Description</span>
                <span className="my-bag-page__items__headers__price">Price</span>
                <span className="my-bag-page__items__headers__remove">Remove</span>
              </section>
              {
                items.map(item => <BagItem key={item.id} {...item} onRemove={basketRemove}/>)
              }
              <div className="my-bag-page__items__footer">
                <div className="my-bag-page__items__offer-code">
                  <label className="my-bag-page__items__offer-code__label" htmlFor="offer-code">Offer Code:</label>
                  <input className="my-bag-page__items__offer-code__input" id="offer-code" value={offerCode ?? ''} onChange={(e) => setOfferCode(e.target.value)} />
                </div>
                <div className="my-bag-page__items__summary">
                  { discount ? <span className="my-bag-page__items__discount">Discount: -{ formatPrice(discount) }</span> : null }
                  <span className="my-bag-page__items__total">Total Cost: {formatPrice(Math.max(0.00, totalCost - discount))}</span>
                </div>
              </div>
            </>
            : null
          }
          {!orderId && !items?.length && !loading ? <p className="my-bag-page__items__empty">No items currently in your bag</p> : null}
          {orderId ? <div className="my-bag-page__complete">
            <p className="my-bag-page__complete__header">Order Reference: #{orderId}</p>
            <p className="my-bag-page__complete__message">Thank you for your order!</p>
            <p className="my-bag-page__complete__message">You will receive an email once your order is processed.</p>
          </div> : null}
        </div>
        <section className="my-bag-page__options">
          <NavLink to="/shop" className="my-bag-page__options__continue">Continue Shopping</NavLink>
          {items?.length ? <div ref={buttonRef} id="paypal-checkout-button" className={`my-bag-page__options__checkout ${paymentMethod === 'card' ? 'my-bag-page__options__checkout--card' : ''}`}/> : null}
        </section>
        { ['ALLOCATED', 'INCOMPLETE', 'APPROVING'].includes(checkoutStatus) ?
          <div className="my-bag-page__checkout__blocker">
            <Spinner dark={false} />
          </div>
          : null
        }
      </div>
    </Page>
  );
};

export { MyBag as default };