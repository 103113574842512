import React from 'react';

import './scss/edit-slides.scss';

const EditSlides = ({ maxItems, children, onAdd, onRemove, onOrderChange }) => {
  return <div className="cms__slides">
    { children?.length < maxItems ? <button className="cms__slides__add" onClick={onAdd}>Add New Item</button> : null }
    { children ? children.map((child, index) => <div key={`slide_${child.props.id}`} className="cms__slides__slide">
      <div className="cms__slides__slide__content">
        {child}
      </div>
      <div className="cms__slides__slide__options">
        { index > 0 ? <button className="cms__slides__slide__options__up" onClick={() => onOrderChange({ id: child.props.id, fromIndex: index, toIndex: index - 1 })}>Up</button> : null }
        { index < children.length - 1 ? <button className="cms__slides__slide__options__down" onClick={() => onOrderChange({ id: child.props.id, fromIndex: index, toIndex: index + 1 })}>Down</button> : null }
        <button className="cms__slides__slide__options__remove" onClick={() => onRemove({ id: child.props.id, index })}>Remove</button>
      </div>
    </div>) : null }
  </div>;
};

export { EditSlides as default };