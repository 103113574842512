import { useCallback, useContext, useState } from 'react';
import { store } from '../providers/CmsProvider';
import useGraph from './useGraph';

const useToken = () => {
  const { getMutation } = useContext(store);
  const [{ loading, error }, { request }] = useGraph();
  const [token, setToken] = useState();

  const refresh = useCallback(async (formId) => {
    const { data: { token: { token: jwt } = {} } = {} } = await request(getMutation('token'), { formId }) || {};
    if (jwt) {
      setToken(jwt);
    }
  }, [request, getMutation, setToken]);

  return [{ token, loading, error }, refresh];
};

export { useToken as default };