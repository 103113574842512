import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import config from '../config.json';

const escapeValue = (value = '') => `${value}`
  .replace(/\s+&\s+/g, ' &amp; ')
  .replace(/"/g, '&quot;')
  .replace(/>/g, '&gt;')
  .replace(/</g, '&lt;');

const upsertMetaTag = ({ name, content, property }) => {
  try {
    const existing = [...document.getElementsByTagName('meta')].find(elm => property ? elm.getAttribute('property') === property : elm.getAttribute('name') === name);
    if (existing) {
      existing.content = escapeValue(content);
    } else {
      const meta = document.createElement('meta');
      if (property) {
        meta.setAttribute('property', property);
      } else {
        meta.setAttribute('name', name);
      }
      meta.setAttribute('content', escapeValue(content));
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  } catch(e) {
    console.log(e);
  }
};

const parseImage = (url) => {
  try {
    const suffix = /(?:_thumb)?\.(?:jpg|jpeg|png|gif)$/i;
    const parts = url.split(/\//g);
    const filename = parts.pop();
    if (filename) {
      return JSON.parse(window.atob(filename.replace(suffix, '')));
    }
  } catch (e) {
    // failed
  }
};

const trim = (str, length) => {
  try {
    if (str && str.length > length) {
      return `${str.substr(0, length - 3)}...`;
    }
  } catch (e) {
    // failed
  }
  return str;
};

const Page = ({ title, description, image, children, className = '', noIndex = false }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = `The Hidden Jewel Emporium${title ? ` - ${escapeValue(title)}` : ''}`;
    upsertMetaTag({ property: 'og:title', content: trim(`${title || 'The Hidden Jewel Emporium'}`, 35) });
    upsertMetaTag({ property: 'og:type', content: 'website' });
  }, [title]);

  useEffect(() => {
    upsertMetaTag({ name: 'description', content: description });
    upsertMetaTag({ property: 'og:description', content: trim(`${description || ''}`, 65) });
  }, [description]);

  useEffect(() => {
    try {
      const info = parseImage(image);
      if (config?.canonical_prefix && info) {
        const { mimeType, dimensions: { width, height } = {} } = info;
        upsertMetaTag({ property: 'og:image', content: `${config.canonical_prefix}${image}` });
        upsertMetaTag({ property: 'og:image:type', content: mimeType || 'image/jpeg' });
        upsertMetaTag({ property: 'og:image:width', content: width || 300 });
        upsertMetaTag({ property: 'og:image:height', content: height || 300 });
      } else {
        const existing = [...document.getElementsByTagName('meta')].filter(elm => ['og:image', 'og:image:type', 'og:image:width', 'og:image:height'].includes(elm.getAttribute('property')));
        if (existing.length) {
          existing.forEach(elm => document.getElementsByTagName('head')[0].removeChild(elm));
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [image]);

  useEffect(() => {
    try {
      if (config?.canonical_prefix) {
        const href = `${config.canonical_prefix}${location?.pathname ?? ''}`;
        const existing = [...document.getElementsByTagName('link')].find(elm => elm.getAttribute('rel') === 'canonical');
        if (existing) {
          if (!noIndex && href && href.length) {
            existing.href = escapeValue(href);
          } else {
            document.getElementsByTagName('head')[0].removeChild(existing);
          }
        } else if (!noIndex && href && href.length) {
          const link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          link.setAttribute('href', href);
          document.getElementsByTagName('head')[0].appendChild(link);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [location.pathname, noIndex]);

  useEffect(() => {
    if (noIndex) {
      const meta = document.createElement('meta');
      meta.setAttribute('name', 'robots');
      meta.content = 'noindex';
      document.getElementsByTagName('head')[0].appendChild(meta);
    } else {
      const old = [...document.getElementsByTagName('meta')].find(elm => elm.getAttribute('name') === 'robots');
      if (old) {
        document.getElementsByTagName('head')[0].removeChild(old);
      }
    }
  }, [noIndex]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={className}>
      {children}
    </main>
  );
};

export { Page as default };