import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Amplify from '@aws-amplify/core';
import config from '../config.json';

import Header from './Header';
import Footer from './Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Shop from './pages/Shop';
import Product from './pages/Product';
import Wishlist from './pages/Wishlist';
import MyBag from './pages/MyBag';
import CmsProvider from './cms/providers/CmsProvider';
import CmsPanel from './cms/CmsPanel';
import ShopProvider from './providers/ShopProvider';
import ContactSettings from './settings/Contact';
import SocialSettings from './settings/Social';
import AddProduct from './settings/AddProduct';
import OfferCodes from './settings/OfferCodes';
import Admin from './cms/Admin';
import queries from '../graphql/query';
import mutations from '../graphql/mutation';

import '../scss/app.scss';
import NotFound from './pages/NotFound';

Amplify.configure(config);

const App = () => {
  return (
    <Router>
      <CmsProvider queries={queries} mutations={mutations}>
        <ShopProvider>
          <div className="app">
            <Header />
            <Switch>
              <Route exact path="/shop/:type/:slug" component={Product}/>
              <Route exact path="/shop/:filter?" component={Shop}/>
              <Route exact path="/about-me" component={About}/>
              <Route exact path="/lets-talk" component={Contact}/>
              <Route exact path="/wishlist" component={Wishlist}/>
              <Route exact path="/my-bag" component={MyBag}/>
              <Route exact path="/admin">
                <Admin logo="/images/logo.svg" />
              </Route>
              <Route exact path="/" component={Home}/>
              <Route component={NotFound}/>
            </Switch>
            <Footer />
          </div>
        </ShopProvider>
        <CmsPanel>
          <ContactSettings />
          <SocialSettings />
          <AddProduct />
          <OfferCodes />
        </CmsPanel>
      </CmsProvider>
    </Router>
  );
};

export { App as default };