import React, { useState } from 'react';
import Page from '../Page';
import useSettings from '../cms/hooks/useSettings';
import useToken from '../cms/hooks/useToken';
import useGraph from '../cms/hooks/useGraph';
import uuid from '../lib/uuid';
import { contactForm } from '../../graphql/mutation';
import '../../scss/pages/contact.scss';
import Spinner from '../Spinner';

const ERROR = 'Oops! A problem occurred. Please try again';

const Contact = () => {
  const [settings = {}] = useSettings('contact-settings');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [invalid, setInvalid] = useState({});
  const [{ token, loading: tokenLoading }, refreshToken] = useToken();
  const [{ loading: submitting }, { request: submit }] = useGraph();
  const [formId] = useState(uuid());
  const [error, setError] = useState();

  const onChange = (setter, value) => {
    if (!token && !tokenLoading) {
      refreshToken(formId);
    }
    setter(value);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!name.trim().length) {
      errors.name = true;
    }
    if (!/^[^@]+@[^.]+\..+$/.test(email)) {
      errors.email = true;
    }
    if(!/^[0-9+ ]{7,}$/.test(phone)) {
      errors.phone = true;
    }
    if (!message.trim().length) {
      errors.message = true;
    }

    if (Object.keys(errors).length) {
      setInvalid(errors);
    } else {
      try {
        const { data: { contactForm: { successful, message: errorMessage, error } = {} } = {} } = await submit(contactForm, { input: { formId, token, name, email, phone, message } }) || {};
        if (!successful || error) {
          if (errorMessage === 'Invalid token') {
            await refreshToken(formId);
            setError('Oops! Token expired. Please try again');
          } else {
            setError(ERROR);
          }
        } else {
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
          setSent(true);
          setInvalid({});
        }
      } catch (e) {
        setError(ERROR);
      }
    }
  };

  return (
    <Page title="Let's Talk" className="contact-page" description="Have some questions about a product? I am here to help! I do my best to respond to enquiries as quickly as possible, usually within 24 hours.">
      <h2 className="contact-page__header">Let's Talk!</h2>
      <p className="contact-page__info">Have some questions about a product? I am here to help! I do my best to respond to enquiries as quickly as possible,
        usually within 24 hours.</p>
      <section className="contact-page__contacts">
        { settings.whatsapp ? <a className="contact-page__contacts__whatsapp" href={`https://wa.me/${settings.whatsapp.replace(/[^+0-9]/g, '')}`}>WhatsApp Me</a> : null }
        { settings.phone ? <a className="contact-page__contacts__tel" href={`tel:${settings.phone.replace(/[^+0-9]/g, '')}`}>Give me a call on {settings.phone}</a> : null }
        { settings.email ? <a className="contact-page__contacts__email" href={`mailto:${settings.email}`}>{settings.email}</a> : null }
      </section>
      {sent ?
        <p className="contact-page__form--sent">
          <span className="contact-page__form--sent__message">Thank you for your enquiry!</span>
          <span className="contact-page__form--sent__message">I will reply as soon as I can.</span>
        </p>
        :
        <form className="contact-page__form" onSubmit={submitForm}>
          <h3 className="contact-page__form__header">Quick Message Me</h3>
          { error ? <p className="contact-page__form__error">{ error }</p> : null }
          <label className={`contact-page__form__label${invalid.name ? ' contact-page__form__label--invalid' : ''}`}>Your Name:</label>
          <input className={`contact-page__form__field${invalid.name ? ' contact-page__form__field--invalid' : ''}`} onChange={(e) => onChange(setName, e.target.value)} value={name} placeholder="Your Name"/>
          <label className={`contact-page__form__label${invalid.email ? ' contact-page__form__label--invalid' : ''}`}>Your Email Address:</label>
          <input className={`contact-page__form__field${invalid.email ? ' contact-page__form__field--invalid' : ''}`} pattern="^[^@]+@[^@]+\.[^@]+$" title="Please enter a valid email address" maxLength="255" onChange={(e) => onChange(setEmail, e.target.value)} value={email} placeholder="Your Email"/>
          <label className={`contact-page__form__label${invalid.phone ? ' contact-page__form__label--invalid' : ''}`}>Your Phone Number:</label>
          <input className={`contact-page__form__field${invalid.phone ? ' contact-page__form__field--invalid' : ''}`} type="tel" onChange={(e) => onChange(setPhone, e.target.value)} value={phone} placeholder="Your Phone Number"/>
          <label className={`contact-page__form__label${invalid.message ? ' contact-page__form__label--invalid' : ''}`}>Your Message:</label>
          <textarea
            className={`contact-page__form__field${invalid.message ? ' contact-page__form__field--invalid' : ''} contact-page__form__field--multiline`}
            onChange={(e) => onChange(setMessage, e.target.value)}
            value={message}
            placeholder="Write Your Message Here..."
          />
          <button className="contact-page__form__submit" disabled={submitting}>{ submitting ? <Spinner instant={true} dark={false} small={true} /> : null }Submit Form</button>
        </form>
      }
    </Page>
  );
};

export { Contact as default };