import { useCallback, useEffect, useState } from 'react';
import useGraph from './useGraph';

const END_OF_DATA = '~~END~~';
const PAGE_LIMIT = 10;

const useHistory = ({ id, type, query }) => {
  const [{ loading, error, data }, { request }] = useGraph();
  const [showMore, setShowMore] = useState();
  const [{ items, nextToken }, updateHistory] = useState({});

  useEffect(() => {
    if (!loading && !error && data?.history) {
      if (data.history.items?.length) {
        updateHistory((state) => {
          if (state.items?.length) {
            return { ...state, items: [...state.items, ...data.history.items] };
          }
          return { ...state, items: data.history.items };
        });
      }
      const token = data.history.nextToken;
      if (token) {
        setShowMore(() => () => updateHistory((state) => ({ ...state, nextToken: token })));
      } else {
        updateHistory((state) => ({ ...state, nextToken: END_OF_DATA }));
        setShowMore(undefined);
      }
    }
  }, [loading, data, error, updateHistory, setShowMore]);

  useEffect(() => {
    if (nextToken && nextToken !== END_OF_DATA) {
      console.log(`Loading more history data for: ${type}:${id}`);
      request(query, {
        where: {
          id,
          type,
          nextToken,
          limit: PAGE_LIMIT,
        },
      });
    }
  }, [id, type, query, nextToken, request]);

  const run = useCallback(() => {
    updateHistory({});
    console.log(`Loading history data for: ${type}:${id}`);
    request(query, {
      where: {
        id,
        type,
        limit: PAGE_LIMIT,
      },
    });
  }, [id, type, query, request]);

  return [{ loading, error, data: items, showMore }, { request: run }];
};

export { useHistory as default };