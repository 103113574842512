import React from 'react';
import { SettingsButton } from './Settings';

const EditSettingsPanel = (
  {
    header = '',
    children,
    error,
    deleteEnabled,
    close,
    remove,
    submitChanges,
    hasChanged,
  }) => {

  const formatError = (error) => error ? <p className="cms__edit__content__error">
    <span className="cms__edit__content__error__title">Oops!</span>
    <span className="cms__edit__content__error__message">{error}</span>
  </p> : null

  return <aside className="cms__edit cms__edit--settings">
    <div className="cms__edit__content">
      <div className="cms__edit__content__header">
        <h2 className="cms__edit__content__header__title">{header}</h2>
        { deleteEnabled ? <SettingsButton label="Delete Product" onClick={remove} className="cms__edit__content__header__delete-button" confirmation={true} invertIcon={true} icon="/cms/images/iconmonstr-trash-can-1.svg" /> : null }
      </div>
      {formatError(error)}
      { children }
      <div className="cms__edit__content__footer">
        <button className="cms__edit__content__button" onClick={() => close()}>Cancel</button>
        <button className="cms__edit__content__button" disabled={!hasChanged} onClick={() => submitChanges()}>Save</button>
      </div>
    </div>
  </aside>
};

export { EditSettingsPanel as default };