import { useCallback, useEffect, useReducer, useState } from 'react';
import useGraph from '../cms/hooks/useGraph';
import searchReducer, { SET_PRODUCTS, UPDATE_FILTERS, UPDATE_SORT_BY, SET_NEXT_TOKEN } from '../reducers/searchReducer';
import { productSearch } from '../../graphql/query';

const END_OF_DATA = '~~END~~';

const useSearch = ({ defer = false, ...defaults } = {}) => {
  const [{ searchBy, orderBy, limit, productTypeIds, products, nextToken }, dispatch] = useReducer(searchReducer, {
    ...(defaults ?? {}),
  });
  const [{ loading, error, data: results, complete }, { request }] = useGraph();
  const [showMore, setShowMore] = useState();
  const [ready, setReady] = useState(!defer);

  useEffect(() => {
    if (ready && nextToken !== END_OF_DATA) {
      const search = () => {
        const params = {
          where: {
            productTypeIds,
            searchBy,
            orderBy,
            limit,
            nextToken,
          },
        };
        request(productSearch, params);
      };
      search();
    } else {
      console.log(`Deferred search until run() is called`);
    }
  }, [ready, searchBy, orderBy, limit, productTypeIds, nextToken, request]);

  useEffect(() => {
    if (!loading && !error && results) {
      dispatch({ type: SET_PRODUCTS, products: results?.productSearch?.items ?? [] });
      const token = results?.productSearch?.nextToken;
      if (token) {
        setShowMore(() => () => dispatch({ type: SET_NEXT_TOKEN, token }));
      } else {
        dispatch({ type: SET_NEXT_TOKEN, token: END_OF_DATA });
        setShowMore(undefined);
      }
    }
  }, [loading, results, error, setShowMore]);

  const updateFilters = useCallback((productTypeIds) => {
    dispatch({ type: UPDATE_FILTERS, productTypeIds: productTypeIds ?? [] });
  }, [dispatch]);

  const updateSortBy = useCallback(({ searchBy, orderBy, limit }) => {
    dispatch({ type: UPDATE_SORT_BY, searchBy, orderBy, limit });
  }, [dispatch]);

  const run = useCallback(() => {
    setReady((old) => {
      if (!old) {
        console.log(`Starting deferred search...`);
      }
      return true;
    });
  }, [setReady]);

  return [{ loading, complete, error, products, run, showMore }, { updateSortBy, updateFilters }];
};

export { useSearch as default };