import React from 'react';
import { useHistory } from 'react-router-dom';
import { Authenticator, SignIn, ConfirmSignIn, ForgotPassword, RequireNewPassword, VerifyContact, TOTPSetup } from 'aws-amplify-react';

import './scss/admin.scss';

const theme = {
  container: {
    width: '100%'
  },
  sectionHeader: {
    marginTop: '10px',
    marginBottom: '20px',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  toast: {
    display: 'flex',
    flexDirection: 'column',
    background: 'rgb(248, 88, 88)',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
  },
  sectionFooter: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '20px',
    justifyContent: 'space-between',
  },
  sectionFooterSecondaryContent: {
    display: 'flex',
    alignItems: 'center',
  }
};

const Admin = ({ logo }) => {
  const history = useHistory();

  const onAuthStateChange = (state) => {
    if (state === 'signedIn') {
      history.push('/');
    }
  };

  return <div className="admin">
    <div className="admin__content">
      { logo ? <div className="admin__logo-container">
        <img className="admin__logo" src={logo} alt="Logo" />
      </div> : null }
      <Authenticator theme={theme} hideDefault={true} onStateChange={onAuthStateChange}>
        <ForgotPassword />
        <SignIn />
        <ConfirmSignIn />
        <RequireNewPassword />
        <VerifyContact />
        <TOTPSetup />
      </Authenticator>
    </div>
  </div>;
};

export { Admin as default };