import React from 'react';

import './scss/edit-group.scss';

const EditGroup = ({ children }) => {
  return <div className="cms__edit__group">
    { children }
  </div>
};

export { EditGroup as default };