import React, { useEffect } from 'react';
import Settings from '../cms/Settings';
import EditField from '../cms/EditField';

const SocialSettingsPanel = ({ data = {}, invalid, updatePreview, setValidator }) => {
  useEffect(() => {
    setValidator((data = {}) => {
      const errors = {};
      if (data?.facebookLink?.length && !/^https?:\/\//.test(data.facebookLink)) {
        errors.facebookLink = 'Please enter a valid link like https:///www.facebook.com';
      }
      if (data?.ebayLink?.length && !/^https?:\/\//.test(data.ebayLink)) {
        errors.ebayLink = 'Please enter a valid link like https://www.ebay.co.uk';
      }
      if (data?.instagramLink?.length && !/^https?:\/\//.test(data.instagramLink)) {
        errors.instagramLink = 'Please enter a valid link like https://www.instagram.com';
      }
      return errors;
    });
  }, [setValidator]);

  return <>
    <EditField label="Facebook Link:" value={data.facebookLink ?? ''} error={invalid?.facebookLink} onChange={(facebookLink) => updatePreview({ ...data, facebookLink })}/>
    <EditField label="eBay Link:" value={data.ebayLink ?? ''} error={invalid?.ebayLink} onChange={(ebayLink) => updatePreview({ ...data, ebayLink })}/>
    <EditField label="Instagram Link" value={data.instagramLink ?? ''} error={invalid?.instagramLink} onChange={(instagramLink) => updatePreview({ ...data, instagramLink })}/>
  </>;
};

const SocialSettings = () => {
  return <Settings
    id="social-settings"
    typename="SocialSettings"
    icon="/images/iconmonstr-speech-bubble-26.svg"
    label="Social Settings"
    invertIcon={true}
    panel={SocialSettingsPanel}
    cachedEnabled={true}
  />
};

export { SocialSettings as default }