import React, { useEffect } from 'react';
import uuid from './lib/uuid';
import Dynamic from './cms/Dynamic';
import EditField from './cms/EditField';
import EditImage from './cms/EditImage';
import EditSlides from './cms/EditSlides';
import PictureNav from './PictureNav';
import { uncamelCase } from './lib/formatters';

import '../scss/quick-links.scss';
import Spinner from './Spinner';
import ErrorNotice from './ErrorNotice';

const historyFormatters = [
  {
    pattern: /^.*\.id$/,
    formatter: () => '',
  },
  {
    pattern: /^links\.\[(\d+)]\.(.*)/,
    formatter: ({ replace: [match, index, rest] }) => `Link ${parseInt(index, 10) + 1} ${uncamelCase(rest)}` || match,
  }
];

const EditQuickLinks = ({ data = {}, invalid, updatePreview, setValidator, maxItems = 4 }) => {
  const links = data.links ?? [];

  const onAdd = () => {
    if (links.length < maxItems) {
      const newLinks = [...(data.links || [])].map(link => ({ ...link }));
      newLinks.unshift({ id: uuid(), title: '', link: '' });
      updatePreview({ ...data, links: newLinks });
    }
  };

  const onUpdate = (updateId, values) => {
    const newLinks = [...(data.links || [])].map(link => ({ ...link }));
    const link = newLinks.find(({ id }) => id === updateId);
    if (link) {
      Object.keys(values).forEach(key => link[key] = values[key]);
      updatePreview({ ...data, links: newLinks });
    }
  };

  const onRemove = ({ id }) => {
    const newLinks = [...(data.links || [])].filter(({ id: linkId }) => id !== linkId).map(link => ({ ...link }));
    updatePreview({ ...data, links: newLinks });
  };

  const onOrderChange = ({ id, fromIndex, toIndex }) => {
    const newLinks = [...(data.links || [])].map(link => ({ ...link }));
    const other = newLinks[toIndex];
    newLinks[toIndex] = newLinks[fromIndex];
    newLinks[fromIndex] = other;
    updatePreview({ ...data, links: newLinks });
  };

  useEffect(() => {
    setValidator((data = {}) => {
      const errors = {};
      if (data?.links?.length) {
        data.links.forEach(({ id, image, title, link }) => {
          if (!image?.length) {
            errors[id] = errors[id] || {};
            errors[id].image = 'Please select an image';
          }
          if (!(title ?? '').trim().length) {
            errors[id] = errors[id] || {};
            errors[id].title = 'Please enter a title';
          }
          if (!(link ?? '').trim().length || !/^\/|^https?:\/\/.+/.test(link)) {
            errors[id] = errors[id] || {};
            errors[id].link = 'Please enter a valid link like /shop or https://google.com';
          }
        });
      }
      return errors;
    });
  }, [setValidator]);

  return <EditSlides maxItems={4} onAdd={onAdd} onRemove={onRemove} onOrderChange={onOrderChange}>
    { links ? links.map(({ id, image, title, link }) => <div id={id} key={id}>
    <EditImage label="Image:" value={image ?? ''} error={invalid?.[id]?.image} canRemove={true} onChange={(image) => onUpdate(id,{ image })}/>
    <EditField label="Title:" value={title ?? ''} error={invalid?.[id]?.title} onChange={(title) => onUpdate(id, { title })}/>
    <EditField label="Link:" value={link ?? ''} error={invalid?.[id]?.link} onChange={(link) => onUpdate(id, { link })}/>
   </div>) : null }
   </EditSlides>
};

const QuickLinks = ({ loading, error, data: { links } = {} }) => {
  return (
    <section className="quick-links">
      { loading ? <Spinner /> : null}
      { error ? <ErrorNotice error={error} /> : null}
      { links ? <PictureNav links={links} /> : null }
    </section>
  );
};

const DynamicQuickLinks = ({ id }) => <Dynamic id={id} typename="QuickLinks" cacheEnabled={true} component={QuickLinks} editComponent={EditQuickLinks} historyFormatters={historyFormatters} />;

export { DynamicQuickLinks as default };