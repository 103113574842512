import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useAdmin from '../cms/hooks/useAdmin';
import useGraph from '../cms/hooks/useGraph';
import useWishlist from '../hooks/useWishlist';
import Page from '../Page';
import Breadcrumb from '../Breadcrumb';
import ImagePreview from '../ImagePreview';
import Dynamic from '../cms/Dynamic';
import { productTypes as productTypesQuery } from '../../graphql/query';
import EditField from '../cms/EditField';
import EditList from '../cms/EditList';
import EditCheckbox from '../cms/EditCheckbox';
import EditImageList from '../cms/EditImageList';
import { formatPrice, slugify, textToParagraphs } from '../lib/formatters';
import '../../scss/pages/product.scss';
import useBasket from '../hooks/useBasket';
import Spinner from '../Spinner';
import ErrorNotice from '../ErrorNotice';
import useSettings from '../cms/hooks/useSettings';

const NEW_TYPE = '**new**';

const historyFormatters = [
  {
    pattern: /^images\.\[(\d+)]$/,
    formatter: ({ replace: [match, index] }) => `Image (${parseInt(index, 10) + 1})` || match,
  },
  {
    pattern: /^productType\.name$/,
    formatter: () => 'Type',
  },
  {
    pattern: /^productType\..*$/,
    formatter: () => '',
  }
];

const EditProduct = ({ isNew, data, invalid, removed, updatePreview, setSubmitFormatter, setCloseCallback, setValidator }) => {
  const history = useHistory();
  const [ { data: { productTypes = {} } = {} }, { request }] = useGraph();
  const types = productTypes?.types ?? [];

  useEffect(() => {
    setSubmitFormatter((data = {}) => {
      const price = data.price ? parseFloat(data.price) : 0.00;
      return {
        header: data.header,
        description: data.description,
        price,
        promoted: !!data.promoted,
        hidden: !!data.hidden,
        productTypeId: data.productType?.id ? `product-types#${data.productType?.id}` : null,
        images: data.images,
      }
    });
  }, [setSubmitFormatter]);

  useEffect(() => {
    setValidator((data = {}) => {
      const errors = {};
      if (!data?.productType?.id) {
        errors.productTypeId = 'Please select the product type';
      }
      if (!data?.header?.length) {
        errors.header = 'Please enter a header';
      }
      if (!data?.description?.length) {
        errors.description = 'Please enter a description';
      }
      if (!data?.images?.length) {
        errors.images = 'Please select at least one image';
      }
      if (!data?.price || parseFloat(data?.price) <= 0) {
        errors.price = 'Please enter a valid price. It must be more than £0.00';
      }
      return errors;
    });
  }, [setValidator]);

  useEffect(() => {
    if (isNew) {
      setCloseCallback(() => {
        setTimeout(() => history.push(`/shop`), 1000);
      });
    }
  }, [isNew, setCloseCallback, history]);

  useEffect(() => {
    request(productTypesQuery, { id: 'product-types' });
  }, [request]);

  useEffect(() => {
    if (removed) {
      history.push('/shop');
    }
  }, [removed, history]);

  return <>
    <EditList r label="Type:" items={types} selectedId={data?.productType?.id} error={invalid?.productTypeId} onChange={(productTypeId) => updatePreview({ ...data, productType: types.find(({id}) => id === productTypeId) })} />
    <EditField label="Header:" value={data?.header ?? ''} error={invalid?.header} onChange={(header) => updatePreview({ ...data, header })}/>
    <EditField multiline={true} label="Description:" value={data?.description ?? ''} error={invalid?.description} onChange={(description) => updatePreview({ ...data, description })}/>
    <EditField label="Price:" allowed={/^[0-9,.]*$/} value={data?.price ?? '0.00'} error={invalid?.price} onChange={(price) => updatePreview({ ...data, price })}/>
    <EditCheckbox label="Promoted" value={data?.promoted ?? false} onChange={(promoted) => updatePreview({ ...data, promoted })}/>
    <EditCheckbox label="Hidden" value={data?.hidden ?? false} onChange={(hidden) => updatePreview({ ...data, hidden })}/>
    <EditImageList items={data?.images ?? []} error={invalid?.images} onChange={(images) => updatePreview({ ...data, images})} />
   </>;
};

const Product = ({ id, loading, error, notFound, isNew, data: { header, description, price, productType, images } = {} }) => {
  const typeName = productType?.name;
  const title = typeName ? `${typeName} - ${header}` : header ?? '';
  const { type: urlType, slug } = useParams();
  const history = useHistory();
  const [wishlist, { wishlistToggle }] = useWishlist();
  const [basket, { basketAdd }] = useBasket();
  const inBasket = basket?.includes(id) || false;
  const [settings = {}] = useSettings('contact-settings');

  useEffect(() => {
    if (!loading && ((typeName && slugify(typeName) !== urlType) || (slug !== `${header ? `${slugify(header)}-` : ''}${id}`))) {
      history.push(`/shop/${typeName ? slugify(typeName) : urlType}/${header ? `${slugify(header)}-` : ''}${id}`);
    }
  }, [loading, urlType, typeName, history, header, id, slug]);

  useEffect(() => {
    if ((notFound || error) && !isNew) {
      console.log(`404: Product:${id}`);
      history.push(`/shop/${urlType === NEW_TYPE ? '' : urlType}`);
    }
  }, [isNew, notFound, id, urlType, history, error]);

  const checkout = () => {
    history.push('/my-bag');
  };

  return (
    <Page title={title} className="product-page" description={(description ?? '').replace(/\n/g, ' ').replace(/\s+/g, ' ')} image={images?.[0]}>
      <Breadcrumb className="product-page__breadcrumb"/>
      {loading ? <Spinner/> : null}
      {error ? <ErrorNotice error={error} /> : null}
      {header ? <section className="product-page__images">
        <ImagePreview images={images} className="product-page__images__preview"/>
      </section> : null}
      {header ?
        <aside className="product-page__info">
          <h2 className="product-page__info__header">{header}</h2>
          <section className="product-page__info__shop">
            <span className="product-page__info__shop__price">{formatPrice(price)}</span>
            {inBasket ?
              <button className="product-page__info__shop__bag product-page__info__shop__bag--checkout" onClick={() => checkout()}>Added To Bag</button> : null}
            {!inBasket ? <button className="product-page__info__shop__bag" onClick={() => basketAdd(id)}>Add To Bag</button> : null}
            <button className="product-page__info__shop__save" onClick={() => wishlistToggle(id)}>{wishlist?.includes(id) ? 'Remove From' : 'Add To'} Wishlist</button>
            { settings.whatsapp ? <a className="product-page__info__shop__whatsapp" href={`https://wa.me/${settings.whatsapp.replace(/[^+0-9]/g, '')}?text=${escape(`Hi, I am interested is this product: ${window.location}`)}`}>WhatsApp Me</a> : null }
          </section>
          {description ?
            <section className="product-page__info__details">
              <h3 className="product-page__info__details__header">Description & Details</h3>
              {textToParagraphs(description)}
            </section>
            : null}
        </aside>
        : null}
    </Page>
  );
};

const DynamicProduct = () => {
  const [admin] = useAdmin();
  const { type, slug } = useParams();
  const [, id] = /-?([^-]+)$/.exec(slug) || [];
  const [isNew, setIsNew] = useState(type === NEW_TYPE);

  useEffect(() => {
    if (admin === false) {
      setIsNew(false);
    }
  }, [admin, setIsNew]);

  return <Dynamic
    id={id}
    typename="Product"
    deleteEnabled={!isNew}
    component={Product}
    editComponent={EditProduct}
    editHeader={`${isNew ? 'Add' : 'Edit' } Product: ${id}`}
    autoEdit={isNew}
    historyFormatters={historyFormatters}
    isNew={isNew}
  />;
}

export { DynamicProduct as default, Product };