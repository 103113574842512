import React, { useEffect, useState } from 'react';

const EditCheckbox = ({ label, value, onChange }) => {
  const [checked, setChecked] = useState(!!value);

  const onToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(newChecked);
  };

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return <div className="cms__edit__content__field-container cms__edit__content__field-container--checkbox" onClick={onToggle}>
    <span className="cms__edit__content__field-container__label--checkbox">{label}</span>
    <span className={`cms__edit__content__field-container__checkbox ${checked ? 'cms__edit__content__field-container__checkbox--ticked' : ''}`}/>
  </div>;
};

export { EditCheckbox as default };