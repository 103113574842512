const UPDATE_ACTIVE = 'UPDATE_ACTIVE';
const TOGGLE_HISTORY = 'TOGGLE_HISTORY';
const START_EDIT = 'START_EDIT';
const STOP_EDIT = 'STOP_EDIT';

const dynamicReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE:
      if (state.active !== action.active || state.hover !== action.hover) {
        return { ...state, active: !!action.active, hover: !!action.hover };
      } else {
        return state;
      }
    case TOGGLE_HISTORY:
      return { ...state, historyOpen: !state.historyOpen };
    case START_EDIT:
      return { ...state, active: false, editing: true, hover: false };
    case STOP_EDIT:
      return { ...state, active: false, editing: false, historyOpen: false, hover: false };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

export { dynamicReducer as default, UPDATE_ACTIVE, TOGGLE_HISTORY, START_EDIT, STOP_EDIT };