import { useState, useEffect, useCallback } from 'react';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

const useAdmin = () => {
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    const updateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setAdmin(!!user);
      } catch {
        setAdmin(false);
      }
    };
    Hub.listen('auth', updateUser);
    updateUser();
    return () => Hub.remove('auth', updateUser);
  }, []);

  const signOut = useCallback(() => {
    Auth.signOut();
  }, []);

  return [admin, { signOut }];
}

export { useAdmin as default };