import React, { useState } from 'react';
import useToken from '../components/cms/hooks/useToken';
import useGraph from '../components/cms/hooks/useGraph';

import uuid from './lib/uuid';
import { newsletter } from '../graphql/mutation';
import '../scss/newsletter.scss';
import Spinner from './Spinner';

const ERROR = 'Oops! A problem occurred. Please try again';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [signedUp, setSignedUp] = useState(false);
  const [{ token, loading: tokenLoading }, refreshToken] = useToken();
  const [{ loading: submitting }, { request: submit }] = useGraph();
  const [formId] = useState(uuid());
  const [error, setError] = useState();

  const onChange = (e) => {
    setEmail(e.target.value);
    if (!token && !tokenLoading) {
      refreshToken(formId);
    }
  };

  const signUp = async (e) => {
    e.preventDefault();
    try {
      const { data: { newsletter: { successful, message, error } = {} } = {} } = await submit(newsletter, { input: { formId, token, email } }) || {};
      if (!successful || error) {
        if (message === 'Invalid token') {
          await refreshToken(formId);
          setError('Oops! Token expired. Please try again');
        } else {
          setError(ERROR);
        }
      } else {
        setEmail('');
        setSignedUp(true);
      }
    } catch (e) {
      setError(ERROR);
    }
  };

  return (
    <form className="newsletter" onSubmit={signUp}>
      <div className="newsletter__form">
        <h4 className="newsletter__form__header">A Little Birdie Told Me</h4>
        <p className="newsletter__form__description">Sign up to be one of the first to hear about exciting new collections, special offers and much more.</p>
        {error ? <p className="newsletter__form__error">{error}</p> : null}
        {signedUp ?
          <p className="newsletter__form__thanks">Thank you for signing up!</p>
          :
          <>
            <input className="newsletter__form__field" pattern="^[^@]+@[^@]+\.[^@]+$" title="Please enter a valid email address" maxLength="255" required={true} onChange={onChange} value={email} placeholder="Enter your email"/>
            <button className="newsletter__form__button" disabled={submitting}>{ submitting ? <Spinner instant={true} dark={false} small={true} /> : null }Sign up</button>
          </>
        }
      </div>
    </form>
  );
};

export { Newsletter as default };