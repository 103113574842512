const UPDATE_SETTING = 'UPDATE_SETTINGS';
const UPDATE_CONTENT_CACHE = 'UPDATE_CONTENT_CACHE';

const cmsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_SETTING:
      return { ...state, settings: {...state.settings, [action.id]: action.value } };
    case UPDATE_CONTENT_CACHE:
      return { ...state, contentCache: {...state.contentCache, [action.id]: action.value } };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

export { cmsReducer as default, UPDATE_SETTING, UPDATE_CONTENT_CACHE };