import React, { useEffect, useState } from 'react';
import useSettings from '../cms/hooks/useSettings';
import useGraph from '../cms/hooks/useGraph';
import PictureNav from '../PictureNav';
import Trending from '../Trending';
import QuickLinks from '../QuickLinks';
import Hero from '../Hero';
import Page from '../Page';
import { instagram } from '../../graphql/query';

import '../../scss/pages/home.scss';

const Home = () => {
  const [{ instagramLink } = {}] = useSettings('social-settings');
  const [{ data }, { request }] = useGraph();
  const [instagramImages, setInstagramImages] = useState();

  useEffect(() => {
    request(instagram, { id: 'instagram-feed' });
  }, [request]);

  useEffect(() => {
    if (instagramLink && data?.instagram?.images) {
      setInstagramImages(data?.instagram?.images.map(({ image, link }) => ({ image, link: link || instagramLink })))
    }
  }, [instagramLink, data, setInstagramImages]);

  return (
    <Page className="home-page" description="High Quality and Affordable Pre-owned Jewellery. Based in West Yorkshire, UK">
      <Hero id="home-hero" />
      <section className="home-page__content">
        <QuickLinks id="home-quick-links" />
        <Trending />
        { instagramImages?.length ? <section className="home-page__instagram">
          <h2 className="home-page__header">My Instagram</h2>
          <a href="https://www.instagram.com/thehiddenjewelemporium/" rel="noopener noreferrer" className="home-page__instagram__button">Follow me</a>
          <PictureNav links={instagramImages ?? []} withSpaces={true} withTitles={false} />
        </section> : null }
      </section>
    </Page>
  );
};

export { Home as default };