import React, { useCallback, useEffect, useRef } from 'react';
import { getDiff } from './utils/dotify';
import { SettingsButton } from './Settings';
import Spinner from './Spinner';

const EditPanel = (
  {
    header = 'Edit Content',
    id,
    loading,
    data,
    submitting,
    error,
    hasChanged,
    historyOpen,
    historyLoading,
    historyError,
    historyShowMore,
    history,
    close,
    submit,
    remove,
    removing,
    updatePreview,
    submitFormatter,
    toggleHistory,
    historyFormatters = [],
    deleteEnabled,
    children,
    variant,
    invalid,
    validator,
    setInvalid,
  }) => {
  const containerRef = useRef();
  const hasHistory = history?.length;

  const uncamelCase = ({ key }) => key.replace(/[A-Z][^A-Z]/g, (match) => ` ${match}`).replace(/^(.)/, (char) => char.toUpperCase()).trim();

  const formatDate = (iso) => new Date(iso).toLocaleString();

  const formatDiff = (diff) => Object.keys(diff).map((key) =>
    <span key={key} className={`cms__edit__content__history__list__item__value--changes__diff cms__edit__content__history__list__item__value--changes__diff--${diff[key]}`} title={diff[key]}>{key}</span>);

  const submitChanges = useCallback(() => {
    if (typeof validator === 'function') {
      const errors = validator(data) || {};
      if (Object.keys(errors).length) {
        setInvalid(errors);
        return;
      }
    }
    const formatted = typeof submitFormatter === 'function' ? submitFormatter(data) : data;
    submit({ input: { id, ...formatted } });
  }, [submitFormatter, submit, id, data, validator, setInvalid]);

  useEffect(() => {
    if (containerRef?.current && invalid) {
      containerRef.current.scrollTop = 0;
    }
  }, [error, invalid, containerRef]);

  const formatError = (error) => error ? <p className="cms__edit__content__error">
        <span className="cms__edit__content__error__title">Oops!</span>
        <span className="cms__edit__content__error__message">{error}</span>
      </p> : null

  return <aside className={`cms__edit ${variant ? `cms__edit--${variant}` : ''}`}>
    <div ref={containerRef} className="cms__edit__content">
      <div className="cms__edit__content__header">
        <h2 className="cms__edit__content__header__title">{header}</h2>
        { deleteEnabled ? <SettingsButton label="Delete Product" onClick={remove} className="cms__edit__content__header__delete-button" confirmation={true} invertIcon={true} icon="/cms/images/iconmonstr-trash-can-1.svg" /> : null }
      </div>
      {formatError(error ? error : invalid ? 'Please correct the highlighted problems below and try again.' : '')}
      { children }
      <div className="cms__edit__content__footer">
        <button className="cms__edit__content__button cms__edit__content__button--history" onClick={toggleHistory}>{historyOpen ? 'Hide' : 'Show'} History</button>
        <button className="cms__edit__content__button" onClick={() => close()}>Cancel</button>
        <button className="cms__edit__content__button" disabled={!hasChanged} onClick={() => submitChanges()}>Save</button>
      </div>
      { historyOpen ?
        <div className="cms__edit__content__history">
          { historyLoading ? <p>{ hasHistory ? 'Refreshing...' : 'Loading...' }</p> : null }
          {formatError(historyError)}
          { hasHistory ?
              <ul className="cms__edit__content__history__list">
                {history.map(({ version, createdDate, data }, index) => {
                  return <li key={version} onClick={() => updatePreview(data)} className="cms__edit__content__history__list__item">
                    <span className="cms__edit__content__history__list__item__value cms__edit__content__history__list__item__value--date">{formatDate(createdDate)}</span>
                    <span className="cms__edit__content__history__list__item__value cms__edit__content__history__list__item__value--changes">
                      {formatDiff(getDiff({ older: (history[index+1] || {}).data, newer: data, historyFormatters: [ ...historyFormatters, { pattern: /.*/, formatter: uncamelCase } ] }))}
                    </span>
                  </li>
                })}
                { historyShowMore ? <li key="show-more" className="cms__edit__content__history__list__item"><button className="cms__edit__content__button" onClick={historyShowMore}>Show More</button></li> : null }
              </ul>
            : null
          }
          { !historyLoading && !historyError && !hasHistory ? <span className="cms__edit__content__history__empty">No History found</span> : null }
        </div>
        : null }
      { loading || submitting || removing ? <div className="cms__edit__content__ui-blocker"><Spinner /></div> : null }
    </div>
  </aside>
};

export { EditPanel as default };