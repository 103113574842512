import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Dynamic from './cms/Dynamic';
import EditField from './cms/EditField';
import { textToParagraphs } from './lib/formatters';

import '../scss/sidebar.scss';

const EditSidebar = ({ data = {}, invalid, updatePreview, setValidator }) => {
  useEffect(() => {
    setValidator((data = {}) => {
      const errors = {};
      if (!(data?.message ?? '').trim().length) {
        errors.message = 'Please enter a message';
      }
      return errors;
    });
  }, [setValidator]);

  return <>
    <EditField multiline={true} label="Message:" value={data.message ?? ''} error={invalid?.message} onChange={(message) => updatePreview({ ...data, message })}/>
  </>;
};

const Sidebar = ({ header = '', data: { message } = {}, className }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      const onKeyDown = (e) => {
        if (e.key === 'Escape') {
          setOpen(false);
        }
      };
      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [open]);

  return (
    <>
      <button className={className} onClick={() => setOpen(!open)}>{header}</button>
      {open ?
        createPortal(<aside className="sidebar">
          <button className="sidebar__close" onClick={() => setOpen(false)}/>
          <h3 className="sidebar__header">{header}</h3>
          <div className="sidebar__content">
            {textToParagraphs(message)}
          </div>
        </aside>, document.body)
        : null
    }
    </>
  )
};

const DynamicSidebar = ({ id, header, className }) => {
  return <Dynamic id={id} typename="Sidebar" cacheEnabled={true} className={className} header={header} component={Sidebar} editComponent={EditSidebar} />;
}

export { DynamicSidebar as default, Sidebar }