import React from 'react';
import { NavLink } from 'react-router-dom';
import { formatPrice, slugify } from './lib/formatters';
import '../scss/product-list.scss';
import useWishlist from './hooks/useWishlist';

const ProductListItem = ({ id, images, productType, header, price, includeWishlist }) => {
  const { name: type } = productType ?? {};
  const [ image ] = images ?? [];
  const [wishlist, { wishlistToggle }] = useWishlist();

  return (
    <figure className="product-list__item">
      <NavLink to={`/shop/${slugify(type)}/${slugify(header)}-${id}`} className="product-list__item__link">
        <div className="product-list__item__image-container">
          { image ? <img alt={header} className="product-list__item__image" src={image}/> : null }
        </div>
        <figcaption className={`product-list__item__info ${includeWishlist ? 'product-list__item__info--with-wishlist' : ''}`}>
          <span className="product-list__item__info__title">{header}</span>
          {includeWishlist ?
            <button
              className={`product-list__item__info__wishlist ${wishlist?.includes(id) ? 'product-list__item__info__wishlist--active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                wishlistToggle(id);
              }}
            /> : null
          }
          <span className="product-list__item__info__price">{formatPrice(price)}</span>
        </figcaption>
      </NavLink>
    </figure>
  );
};

const ProductList = ({ className = '', products = [], includeWishlist = false, noWrap = false }) => {
  return (
    <section className={`product-list ${noWrap ? 'product-list--no-wrap' : ''} ${className ? className : ''}`}>
      {products.map((product) => <ProductListItem key={product.id} {...product} includeWishlist={includeWishlist}/>)}
    </section>
  );
};

export { ProductList as default };