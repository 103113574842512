import React, { useCallback, useReducer } from 'react';
import cmsReducer, { UPDATE_SETTING, UPDATE_CONTENT_CACHE } from '../reducers/cmsReducer';

const store = React.createContext({});
const { Provider } = store;

const CmsProvider = ({ children, queries = {}, mutations = {}, contentCache = {} }) => {
  const [state, dispatch] = useReducer(cmsReducer, { settings: {}, queries, mutations, contentCache });
  const updateSetting = useCallback(({ id, value }) => dispatch({ type: UPDATE_SETTING, id, value }), [dispatch]);
  const getSetting = useCallback((id) => state.settings[id], [state.settings]);

  const getQuery = useCallback((id) => state.queries[id], [state.queries]);
  const getMutation = useCallback((id) => state.mutations[id], [state.mutations]);

  const getContentCache = useCallback((id) => state.contentCache[id], [state.contentCache]);
  const updateContentCache = useCallback(({ id, value }) => dispatch({ type: UPDATE_CONTENT_CACHE, id, value }), [dispatch]);

  return <Provider value={{ updateSetting, getSetting, getQuery, getMutation, getContentCache, updateContentCache }}>{children}</Provider>
};

export { CmsProvider as default, store };