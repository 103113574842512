const SET_WISHLIST = 'SET_WISHLIST';
const SET_BASKET = 'SET_BASKET';

const shopReducer = (state, action) => {
  switch (action.type) {
    case SET_WISHLIST:
      return { ...state, wishlist: action.wishlist };
    case SET_BASKET:
      return { ...state, basket: action.basket };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

export { shopReducer as default, SET_WISHLIST, SET_BASKET };