import React from 'react';
import '../scss/text-banner.scss';

const TextBanner = ({ header, subHeader, notice = false }) => {
  return (
    <div className={`text-banner ${notice ? 'text-banner--notice' : ''}`}>
      <span className="text-banner__title">{header}</span>
      <span className="text-banner__caption">{subHeader}</span>
    </div>
  );
};

export { TextBanner as default }