import { useCallback, useContext, useEffect, useState } from 'react';
import { store } from '../providers/CmsProvider';

const useContentCache = (id) => {
  const { getContentCache, updateContentCache } = useContext(store);
  const [cache, setCache] = useState(getContentCache(id));

  const onUpdate = useCallback((value) => setCache(value), [setCache]);

  useEffect(() => {
    if (cache) {
      updateContentCache({ id, value: cache });
    }
  }, [id, cache, updateContentCache]);

  return [cache, onUpdate];
};

export { useContentCache as default };