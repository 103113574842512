import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../scss/breadcrumb.scss';

const Breadcrumb = ({ depth = 3, className = '' }) => {
  const location = useLocation();
  const crumbs = ['', ...location.pathname.split('/').filter(s => s.trim().length)].slice(0, depth);

  const formatLabel = (label) => label
    .replace(/-/g, ' ')
    .replace(/\s?,\s?/g, ', ')
    .replace(/,\s?([^,]+)$/, ' & $1');

  const links = crumbs.reduce((list, label, index) => {
    if (index > 0) {
      list.push({ separator: true });
    }
    list.push({
      label: index === 0 ? 'Home' : formatLabel(label),
      to: `${crumbs.slice(0, index).join('/')}/${label}`,
    });
    return list;
  }, []);

  return (
    <ul className={`breadcrumb ${className}`}>
      {links.map(({ to, label, separator }, index) => {
        return (
          separator ?
            <li key={`separator-${index}`} className="breadcrumb__separator"/>
            :
            <li key={to} className="breadcrumb__item">
              <NavLink to={to} className="breadcrumb__item__link">{label}</NavLink>
            </li>
        );
      })}
    </ul>
  );
};

export { Breadcrumb as default };