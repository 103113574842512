import React, { useEffect, useState } from 'react';
import './scss/edit-list.scss';
import { SettingsButton } from './Settings';

const EditList = ({ label, items, invalid, selectedId, required = false, showDelete = false, deleteEnabled = false, onChange, onDelete, error }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  const onSelect = (e, id) => {
    e.preventDefault();
    if (id !== selected) {
      setSelected(id);
      if (onChange) {
        onChange(id);
      }
    }
  };

  const toggleOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const deleteItem = () => {
    if (selected && onDelete) onDelete(selected);
  };

  useEffect(() => {
    setSelected(selectedId);
  }, [selectedId, setSelected]);

  useEffect(() => {
    if (!selected && items?.length) {
      setSelected(items.find(({ id }) => id === selectedId)?.id);
    }
  }, [items, setSelected, selectedId, selected]);

  useEffect(() => {
    if (open) {
      const onKeyDown = (e) => {
        if (e.key === 'Escape') {
          setOpen(false);
        }
      };
      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      const onClick = (e) => {
        setOpen(false);
      };
      window.addEventListener('click', onClick);
      return () => {
        window.removeEventListener('click', onClick);
      };
    }
  }, [open]);

  return <div className="cms__edit__content__field-container">
    <label className="cms__edit__content__field-container__label">{label}</label>
    { error ? <p className="cms__edit__content__field-container__error">{error}</p> : null }
    <div className="cms__edit__content__field-container__list-group">
      <div onClick={toggleOpen} className={`cms__edit__content__field-container__list ${showDelete ? 'cms__edit__content__field-container__list--delete' : ''} ${open ? 'cms__edit__content__field-container__list--open' : ''} ${ error? 'cms__edit__content__field-container__list--error' : '' }`}>
        <span className="cms__edit__content__field-container__list__selected">{items.find(({ id } = {}) => selected === id)?.name ?? 'Please select...'}</span>
        {open ?
          <ul className="cms__edit__content__field-container__list__items">
            {
              items.map(({ id, name } = {}) => (
                <li key={id} onClick={(e) => onSelect(e, id)} className={`cms__edit__content__field-container__list__items__item ${invalid?.length && invalid.includes(id)? 'cms__edit__content__field-container__list__items__item--error' : ''}`}>{name}</li>
              ))
            }
          </ul>
          : null
        }
      </div>
      { showDelete ? <SettingsButton label="Delete Item" onClick={deleteItem} className="cms__edit__content__field-container__list-delete-button" disabled={!deleteEnabled} confirmation={true} invertIcon={true} icon="/cms/images/iconmonstr-trash-can-1.svg" /> : null }
    </div>
  </div>;
};

export { EditList as default };