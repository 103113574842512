const hero = `query($id: ID!) {
  hero(id: $id) {
    link 
    image 
    header
    subHeader
    buttonLabel
  }
}`;

const siteBanner = `query($id: ID!) {
  siteBanner(id: $id) {
    message
  }
}`;

const sidebar = `query($id: ID!) {
  sidebar(id: $id) {
    message
  }
}`;

const contactSettings = `query($id: ID!) {
  contactSettings(id: $id) {
    whatsapp
    phone
    email
  }
}`;

const socialSettings = `query($id: ID!) {
  socialSettings(id: $id) {
    facebookLink
    ebayLink
    instagramLink
  }
}`;

const productTypes = `query($id: ID!) {
  productTypes(id: $id) {
    defaultHeader
    defaultSubHeader
    mixedHeader
    mixedSubHeader
    types {
      id
      name
      header
      subHeader
    }
  }
}`;

const product = `query($id: ID!) {
  product(id: $id) {
    productType {
      id
      name
    }
    header
    description
    price
    images
    hidden
    promoted
  }
}`;

const products = `query($ids: [ID!]!) {
  products(ids: $ids) {
    id
    productType {
      id
      name
    }
    header
    description
    price
    images
    hidden
    promoted
  }
}`;

const instagram = `query($id: ID!) {
  instagram(id: $id) {
    images {
      caption
      image
      link
    }
  }
}`;

const quickLinks = `query($id: ID!) {
  quickLinks(id: $id) {
    links {
      id
      image
      title
      link
    }
  }
}`;

const history = `query($where: HistoryInput!) {
  history(where: $where) {
    nextToken
    items {
      version
      createdDate
      data {
        ... on Hero {
          link 
          image 
          header
          subHeader
          buttonLabel
        }
        ... on SiteBanner {
          message
        }
        ... on Sidebar {
          message
        }
        ... on ContactSettings {
          whatsapp
          phone
          email
        }
        ... on SocialSettings {
          facebookLink
          ebayLink
          instagramLink
        }
        ... on ProductTypes {
          defaultHeader
          defaultSubHeader
          mixedHeader
          mixedSubHeader
          types {
            id
            name
            header
            subHeader
          }
        }
        ... on Product {
          productType {
            id
            name
          }
          header
          description
          price
          images
          hidden
          promoted
        }
        ... on QuickLinks {
          links {
            id
            image
            title
            link
          }
        }
      }
    }
  }
}`;

const productSearch = `query($where: ProductSearchInput!) {
  productSearch(where: $where) {
    nextToken
    items {
      id
      productType {
        id
        name
      }
      header
      price
      images
    }
  }
}`;

const offerCode = `query($code: ID!) {
  offerCode(code: $code) {
    details {
      code
      discountAmount
      discountType
    }
    status
  }
}`;

const offerCodes = `query($where: OfferCodesInput!) {
  offerCodes(where: $where) {
    nextToken
    items {
      code
      validFrom
      validTo
      discountAmount
      discountType
      enabled
      maxClaims
      claims
    }
  }
}`;

const all = {
  history,
  hero,
  siteBanner,
  sidebar,
  contactSettings,
  socialSettings,
  productTypes,
  product,
  products,
  productSearch,
  instagram,
  quickLinks,
  offerCode,
  offerCodes,
};

export { all as default, history, hero, siteBanner, sidebar, contactSettings, socialSettings, productTypes, product, products, productSearch, instagram, quickLinks, offerCode, offerCodes };