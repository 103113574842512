import React from 'react';
import { NavLink } from "react-router-dom";

import '../scss/main-nav.scss';

const MainNav = () => {
  // todo: use react router
  const links = [
    { title: 'Shop', url: '/shop' },
    { title: 'About Me', url: '/about-me' },
    { title: "Let's Talk!", url: '/lets-talk' },
  ];

  return (
    <nav className="main-nav">
      <ul className="main-nav__items">
        {links.map(({ title, url }) => (
          <li key={url} className="main-nav__items__item">
            <NavLink to={url} className="main-nav__items__item__link" activeClassName="main-nav__items__item__link--selected">{title}</NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export { MainNav as default };