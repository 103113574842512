import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { createPortal } from 'react-dom';
import Storage from '@aws-amplify/storage';
import useAdmin from './hooks/useAdmin';
import galleryReducer from './reducers/galleryReducer';
import ConfirmButton from './ConfirmButton';
import './scss/gallery.scss';
import Spinner from './Spinner';

const FILES = '$files';
const FOLDERS = '$folders';
const PATH = '$path';
const SORT_NAME = 'name';
const SORT_NEWEST = 'newest';
const SORT_OLDEST = 'oldest';
const SORT_LARGEST = 'largest';
const SORT_SMALLEST = 'smallest';

const suffix = /(?:_thumb)?\.(?:jpg|jpeg|png|gif)$/i;
const isAllowedType = /_thumb\.(?:jpg|jpeg|png|gif)$/i;
const isFolder = ({ key, size }) => !/\.[^.]+$/.test(key) && size === 0;
const getMimeType = ({ type }) => type;
const getFileInfo = (raw) => JSON.parse(window.atob(raw.replace(suffix, '')));
const getTreeRoot = (branch) => branch && branch.parent ? getTreeRoot(branch.parent) : branch;
const generateFolder = ({ path, parent }) => ({ [FILES]: [], [FOLDERS]: {}, [PATH]: path, parent });
const generateS3Url = (key) => `/public/${key}`;
const getBreadcrumb = (folder) => folder ? [...getBreadcrumb(folder.parent), folder] : [];
const isEmptyFolder = ({ [FOLDERS]: folders, [FILES]: files, [PATH]: path } = {}) =>  path && path.length && folders && !Object.keys(folders).length && files && !files.length;
const formatFolderKey = (path) => `${path.replace(/\/$/, '')}/`;
const formatSize = (bytes, decimals = 2) => {
  if (!bytes) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}

const useGallery = () => {
  const [showing, setShowing] = useState(false);
  const open = () => setShowing(true);
  const close = () => setShowing(false);
  const toggle = () => setShowing(!showing);
  const parse = (url) => {
    const parts = url.split(/\//g);
    const filename = parts.pop();
    if (filename) {
      const [name, extension] = filename.split('.');
      return {
        ...getFileInfo(filename),
        url,
        thumb: `${parts.join('/')}/${name}_thumb.${extension}`,
      };
    }
  };
  return [showing, { open, close, toggle, parse }];
}

const reloadPath = async ({ path, cache }) => {
  const pathMap = {};
  const resetMap = {};
  const isRootReload = !path.length;
  const useCache = !isRootReload && cache;
  const ensureFolderExists = ({ parts = [], tree }) => parts.reduce((parent, part) => {
    if (!part.length) return parent;

    const currentPath = parent[PATH] ? `${parent[PATH]}/${part}` : part;
    const isReloadPath = path === currentPath;
    if ((isReloadPath || isRootReload) && !resetMap[currentPath]) {
      delete parent[FOLDERS][part];
      resetMap[currentPath] = true;
    }

    if (!parent[FOLDERS][part]) {
      parent[FOLDERS][part] = generateFolder({ path: currentPath, parent });
    }

    pathMap[currentPath] = parent[FOLDERS][part];
    return parent[FOLDERS][part];
  }, tree);

  const data = (await Storage.list(path) || []).reduce((tree, { key, size, lastModified }) => {
    const folder = isFolder({ key, size });
    if (!isAllowedType.test(key) && !folder) return tree;

    const parts = key.split('/');
    const filename = !folder ? parts.pop() : null;
    const parent = ensureFolderExists({ parts, tree });

    if (!folder) {
      const info = getFileInfo(filename);
      parent[FILES].push({ ...info, key, url: generateS3Url(key), lastModified });
    }

    return tree;
  }, useCache ? cache : generateFolder({ path: '' }));

  return pathMap[path] || data;
};

const GalleryList = ({ [FOLDERS]: folders, [FILES]: files, [PATH]: path, parent, setFolder, createFolder, selected, toggleSelection, uploadFiles, sort }) => {
  const [adding, setAdding] = useState(false);
  const [addingValue, setAddingValue] = useState('');
  const selectedCount = Object.keys(selected).length;
  const navLocked = adding || selectedCount;

  const sortedFiles = [...files].sort((a, b) => {
    switch (sort) {
      case SORT_NAME:
        return a.name.localeCompare(b.name);
      case SORT_NEWEST:
        return b.lastModified.getTime() - a.lastModified.getTime();
      case SORT_OLDEST:
        return a.lastModified.getTime() - b.lastModified.getTime();
      case SORT_LARGEST:
        return b.size - a.size;
      case SORT_SMALLEST:
        return a.size - b.size;
      default:
        return 0;
    }
  });
  const sortedFolders = Object.keys(folders).sort((a, b) => a.localeCompare(b));

  const addFolder = () => {
    if (!navLocked) {
      setAddingValue('');
      setAdding(true);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      setAdding(false);
    } else if (e.key === 'Enter') {
      if (addingValue && addingValue.length) {
        createFolder(path && path.length ? `${path}/${addingValue}` : addingValue);
        setAdding(false);
      }
    }
  };

  const updateAddingValue = (e) => setAddingValue(
    (e.target.value || '')
      .replace(/[^a-z0-9_\- ]/ig, '')
      .replace(/\s+/g, '-'),
  );

  return (
    <ul className={`gallery__list ${adding ? 'gallery__list--adding' : ''} ${selectedCount ? 'gallery__list--selected' : ''}`}>
      {parent ? <li key={'back'} className="gallery__list__item">
        <div className="gallery__list__item__content" onClick={(e) => !navLocked && setFolder(parent)}>
          <span className="gallery__list__item__content__back"/>
          <span className="gallery__list__item__content__label">Back</span>
        </div>
      </li> : null}
      <li key={'upload'} className="gallery__list__item">
        <div className="gallery__list__item__content">
          <input className="gallery__list__item__content__upload" disabled={navLocked} type="file" onChange={uploadFiles} multiple={true}
                 accept="image/png, image/jpeg, image/gif"/>
          <span className="gallery__list__item__content__label--upload">Upload</span>
        </div>
      </li>
      <li key={'add-folder'} className="gallery__list__item gallery__list__item--adding">
        <div className="gallery__list__item__content" onClick={addFolder}>
          <span className="gallery__list__item__content__folder--add"/>
          {adding ?
            <input
              className="gallery__list__item__content__add-folder"
              autoFocus={true}
              placeholder="Enter name or Esc to cancel"
              value={addingValue}
              onBlur={(e) => e.target.focus()}
              onChange={updateAddingValue}
              onKeyDown={onKeyDown}
            /> : <span className="gallery__list__item__content__label">Add Folder</span>
          }
        </div>
      </li>
      {sortedFolders.map((folder) => <li key={folder} className="gallery__list__item">
        <div className="gallery__list__item__content" onClick={(e) => !navLocked && setFolder(folders[folder])}>
          <span className="gallery__list__item__content__folder"/>
          <span className="gallery__list__item__content__label">{folder}</span>
        </div>
      </li>)}
      {sortedFiles.map((image) =>
        <li
          key={image.key}
          className={`gallery__list__item gallery__list__item--image ${image.key in selected ? 'gallery__list__item--selected' : ''}`}
          onClick={(e) => !adding && toggleSelection(image)}
          onDoubleClick={(e) => !adding && toggleSelection(image, true)}
        >
          <div className="gallery__list__item__content">
            <img className='gallery__list__item__content__image' src={image.url} alt={image.name}/>
            <span className="gallery__list__item__content__label">{image.name} ({formatSize(image.size)})</span>
          </div>
        </li>,
      )}
    </ul>
  );
};

const resizeImage = ({ file, width, mimeType }) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      const elem = document.createElement('canvas');

      if (!width) {
        if (img.width > img.height * 2 && img.width > 1256) {
          // full width image like a banner
          width = 1256;
        } else if (img.width > 680) {
          // product image
          width = 680;
        } else {
          width = img.width;
        }
      }

      const scaleFactor = width / img.width;
      elem.width = width;
      elem.height = img.height * scaleFactor;

      const ctx = elem.getContext('2d');
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
      ctx.canvas.toBlob((blob) => {
        resolve({
          blob,
          dataUrl: elem.toDataURL(mimeType),
          width: elem.width,
          height: elem.height,
        });
      }, mimeType, 0.9);
    };
    img.onerror = (e) => reject(e);
  };
  reader.onerror = (e) => reject(e);
});

const UploadItem = ({ path, file, uploadComplete }) => {
  const [id, setId] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState('');
  const [thumbnail, setThumbnail] = useState();
  const [image, setImage] = useState();
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(false);
  const [uploadedThumbnail, setUploadedThumbnail] = useState(false);
  const mimeType = getMimeType(file);
  const parts = file.name.split('.');
  const extension = parts.pop();
  const filename = parts.join('.');
  const prefix = path && path.length ? `${path}/` : '';

  useEffect(() => {
    if (!thumbnail && image) {
      setMessage('Creating Thumbnail...');
      resizeImage({ file: image, width: 200, mimeType}).then(({ blob, dataUrl, width, height }) => {
        const resized = new File([blob], filename, {
          mimeType,
          lastModified: Date.now(),
        });
        setImageSrc(dataUrl);
        setThumbnail(resized);
        setId(window.btoa(JSON.stringify({
          name: `${filename}.${extension}`,
          dimensions: { width, height },
          size: image.size,
          mimeType,
        })));
      });
    }
  }, [setMessage, setId, setImageSrc, setThumbnail, thumbnail, image, mimeType, filename, extension]);

  useEffect(() => {
    if (!image) {
      setMessage('Resizing image...');
      resizeImage({ file, mimeType}).then(({ blob }) => {
        const resized = new File([blob], filename, {
          mimeType,
          lastModified: Date.now(),
        });
        setImage(resized);
      });
    }
  }, [setMessage, setImageSrc, setImage, image, file, mimeType, filename, extension]);

  useEffect(() => {
    const upload = async () => {
      try {
        setProgress(0);
        setMessage('Uploading Image...');
        await Storage.put(`${prefix}${id}.${extension}`, image, {
          contentType: mimeType,
          progressCallback: ({ loaded, total }) => {
            setProgress(Math.floor((loaded / total) * 100));
          },
        });
        setUploadedFile(true);
      } catch (e) {
        setError(e.message);
      }
    };
    if (id && !uploadedFile) {
      upload();
    }
  }, [id, uploadedFile, prefix, image, mimeType, extension, setProgress, setMessage, setUploadedFile, setError]);

  useEffect(() => {
    const upload = async () => {
      try {
        setProgress(0);
        setMessage('Uploading Thumbnail...');
        await Storage.put(`${prefix}${id}_thumb.${extension}`, thumbnail, {
          contentType: mimeType,
          progressCallback: ({ loaded, total }) => {
            setProgress(Math.floor((loaded / total) * 100));
          },
        });
        setUploadedThumbnail(true);
        setMessage('Upload Complete!');
        uploadComplete();
      } catch (e) {
        setError(e.message);
      }
    };
    if (id && thumbnail && uploadedFile && !uploadedThumbnail) {
      upload();
    }
  }, [id, thumbnail, mimeType, prefix, uploadedFile, uploadedThumbnail, extension, uploadComplete, setProgress, setMessage, setUploadedThumbnail, setError]);

  return (
    <li className="gallery__list__item">
      <div className="gallery__list__item__content">
        <span className="gallery__list__item__content__status"><span className={`gallery__list__item__content__status__message ${error ? 'gallery__list__item__content__status__message--error' : ''}`}>{error? `Error: ${error}` : message}{`${progress ? ` ${progress}%` : ''}`}</span></span>
        {imageSrc ? <img className='gallery__list__item__content__image' src={imageSrc} alt={filename}/> : null}
        <span className="gallery__list__item__content__label">{`${filename}.${extension}`}</span>
      </div>
    </li>
  );
};

const UploadList = ({ path, uploads = [], uploadComplete }) => {
  return (
    <ul className="gallery__list">
      {uploads.map((file) => <UploadItem key={file.name} path={path} file={file} uploadComplete={uploadComplete}/>)}
    </ul>
  );
};

const Gallery = ({ path = '', multiple = true, allowSelect = true, onClose }) => {
  const [admin] = useAdmin();
  const [{ loading, error, folder, uploads, sort, selected, selectedCount, uploading, uploadedCount, quickSelect }, dispatch] = useReducer(galleryReducer, {
    loading: false,
    error: undefined,
    folder: undefined,
    uploads: undefined,
    sort: SORT_NAME,
    selected: {},
    selectedCount: 0,
    uploading: false,
    uploadedCount: 0,
    quickSelect: false,
    multiple,
  });

  const uploadFiles = ({ target: { files } }) => dispatch({ type: 'UPLOAD_START', uploads: [...files] });
  const uploadComplete = () => dispatch({ type: 'UPLOAD_COMPLETE' });
  const toggleSelection = (image, quickSelect = false) => dispatch({ type: 'TOGGLE_SELECTED', image, quickSelect: quickSelect && allowSelect });
  const changeSort = (e) => dispatch({ type: 'SET_SORT', sort: e.target.value });
  const setFolder = (folder) => dispatch({ type: 'SET_FOLDER', folder });

  const refresh = useCallback(async ({ path, folder, useCache = false }) => {
    console.log(`Fetching: ${path}`);
    dispatch({ type: 'LOADING' });
    try {
      const data = await reloadPath({ path, cache: useCache ? getTreeRoot(folder) : undefined });
      dispatch({ type: 'SET_FOLDER', folder: data });
    } catch (e) {
      dispatch({ type: 'ERROR', error: e.message });
    }
  }, []);

  const createFolder = async (path) => {
    if (!path || !path.length) return;
    try {
      console.log(`Creating folder: ${path}`);
      await Storage.put(formatFolderKey(path));
      refresh({ path: folder[PATH], useCache: true });
    } catch (e) {
      dispatch({ type: 'ERROR', error: e.message });
    }
  };

  const deleteSelected = async () => {
    try {
      const keys = Object.keys(selected);
      if (keys.length) {
        await Promise.all(Object.keys(selected).map((key) => Storage.remove(key)));
        dispatch({ type: 'REMOVE_SELECTED', keys });
        refresh({ path: folder[PATH], useCache: true });
      }
    } catch(e) {
      dispatch({ type: 'ERROR', error: e.message });
    }
  };

  const deleteFolder = async () => {
    try {
      if (folder && folder[PATH].length) {
        await Storage.remove(formatFolderKey(folder[PATH]));
        dispatch({ type: 'CLEAR_SELECTED' });
        refresh({ path: folder.parent ? folder.parent[PATH] : '', useCache: true });
      }
    } catch (e) {
      dispatch({ type: 'ERROR', error: e.message });
    }
  };

  const closeGallery = useCallback((withSelected = true) => {
    if (!withSelected) return onClose([]);
    onClose(Object.keys(selected).map((key) => {
      const { name, mimeType, dimensions = {}, size, url, lastModified } = selected[key];
      return {
        name,
        mimeType,
        dimensions: { ...dimensions },
        size,
        url: url.replace('_thumb.', '.'),
        thumbUrl: url,
        lastModified: new Date(lastModified),
      };
    }));
  }, [onClose, selected]);

  useEffect(() => {
    if (quickSelect) {
      closeGallery(true);
    }
  }, [quickSelect, closeGallery]);

  useEffect(() => {
    if (admin) {
      refresh({ path });
    }
  }, [admin, path, refresh]);

  useEffect(() => {
    if (uploading && uploadedCount >= uploads.length) {
      dispatch({ type: 'UPLOAD_RESET' });
      refresh({ path: folder[PATH], folder, useCache: true });
    }
  }, [folder, uploadedCount, uploads, uploading, refresh]);

  return createPortal(<div className="gallery">
    <div className="gallery__content">
      <header className="gallery__content__header">
        {uploading ?
          <h1 className="gallery__content__header__title">{`Uploading ${uploads.length} File${uploads.length ? 's' : ''}`}</h1>
          :
          <h1
            className="gallery__content__header__title">{selectedCount ? `${selectedCount} Selected Image${selectedCount > 1 ? 's' : ''}` : `${multiple ? 'Select some images' : 'Select an image'}`}</h1>
        }
        <ul className={`gallery__content__header__breadcrumb ${selectedCount ? 'gallery__content__header__breadcrumb--disabled' : ''}`}>
          {folder ? getBreadcrumb(folder).map((folder) => <li
            key={folder[PATH]}
            className="gallery__content__header__breadcrumb__item"
            onClick={(e) => !uploading && !selectedCount && setFolder(folder)}
          >
            {folder[PATH].split('/').pop() || 'Home'}
          </li>) : null}
        </ul>
        <div className="gallery__content__header__sort">
          <span className="gallery__content__header__sort__label">Sort By:</span>
          <select className="gallery__content__header__sort__list" disabled={uploading} defaultValue={sort} onChange={changeSort}>
            <option value={SORT_NAME}>Name</option>
            <option value={SORT_NEWEST}>Newest</option>
            <option value={SORT_OLDEST}>Oldest</option>
            <option value={SORT_LARGEST}>Largest</option>
            <option value={SORT_SMALLEST}>Smallest</option>
          </select>
        </div>
      </header>
      <div className="gallery__content-container">
        {error ? <p className="gallery__content__error">{error}</p> : null}
        {!uploading && folder ?
          <GalleryList {...folder} sort={sort} setFolder={setFolder} createFolder={createFolder} selected={selected} uploadFiles={uploadFiles}
                       toggleSelection={toggleSelection}/> : null}
        {folder && uploading ?
          <UploadList path={folder[PATH]} uploads={uploads} uploadComplete={uploadComplete}/> : null}
        {loading ? <Spinner forPanel={false} /> : null}
      </div>
      <footer className="gallery__content__footer">
        {isEmptyFolder(folder) ?
          <ConfirmButton className="gallery__content__footer__button gallery__content__footer__button--left" onClick={deleteFolder} disabled={uploading}>Delete
            Folder</ConfirmButton>
          :
          <ConfirmButton className="gallery__content__footer__button gallery__content__footer__button--left" onClick={deleteSelected}
                  disabled={uploading || !selectedCount}>Delete</ConfirmButton>
        }
        <button className="gallery__content__footer__button" onClick={() => closeGallery(false)}>Close</button>
        { allowSelect ? <button className="gallery__content__footer__button" onClick={() => closeGallery(true)} disabled={uploading || !selectedCount}>Apply</button> : null }
      </footer>
    </div>
  </div>, document.body);
};

export { Gallery as default, useGallery };