import React from 'react';
import { NavLink } from 'react-router-dom';
import Page from '../Page';
import '../../scss/pages/about.scss';

const About = () => {
  return (
    <Page title="About Me" className="about-page" description="Let me tell you a little about me... My name is Laura and I LOVE everything jewellery!">
      <section className="about-page__header">
        <div className="about-page__header__image-container about-page__header__image-container--hands">
          <img className="about-page__header__image" src="/images/desktop-about-me-image1.jpg" alt="" />
        </div>
        <div className="about-page__header__image-container about-page__header__image-container--laura">
          <img className="about-page__header__image" src="/images/desktop-about-me-image4.jpg" alt="" />
        </div>
        <div className="about-page__header__content">
          <h2 className="about-page__header__title">Welcome<br/>To The Hidden Jewel Emporium!</h2>
          <p>Let me tell you a little bit about me...</p>
          <p>My name is Laura and<br/>I LOVE everything Jewellery!</p>
        </div>
      </section>
      <section className="about-page__info">
        <div className="about-page__info__content">
          <p>From the age of 15 I began working in our family run jewellery shop in Leeds city centre. At that age the 2 things I loved were jewellery and art & design so at the age of 18 I left Yorkshire to go to Preston to study jewellery design at the University of Central Lancashire. I graduated in 2006 with a 2:1 honours degree.</p>
          <p>Since then I carried on working for the family business alongisde having a family of my own.</p>
          <p>Having worked with jewellery for over 20 years what I enjoy the most is discovering jewellery items that I've never seen before. Its hard to explain but dealing in second-hand jewellery is like treasure hunting. I love finding unique, unusual designs that have a history to them.</p>
          <p>This is what I want to bring to you, my customers.</p>
          <p>I want you to find your hidden jewels here.</p>
        </div>
        <div className="about-page__info__image-container about-page__info__image-container--quote">
          <img className="about-page__info__image" src="/images/desktop-about-me-image2.jpg" alt="" />
        </div>
      </section>
      <section className="about-page__info">
        <div className="about-page__info__image-container about-page__info__image-container--hands">
          <img className="about-page__info__image" src="/images/desktop-about-me-image3.jpg" alt="" />
        </div>
        <div className="about-page__info__content">
          <p>I pride myself on excellent customer service, I only sell quality items and I like to keep my pricing realistic and affordable.</p>
          <p>Even though this is an online business, being a northern lass I'm all about getting to know my customers and offering the best possible customer service.</p>
          <p>Trust is so important when purchasing an item of jewellery so please be assured that I am here to answer any questions you may have about any of my products or services.</p>
          <p>If there is something in particular you are looking for and can't find it in my stock, please contact me and let me know what you are seeking and I will try my best to help you find it.</p>
        </div>
      </section>
      <section className="about-page__footer">
        <p>I hope you enjoy looking through my finds as much as I love showing you them</p>
        <p className="about-page__footer__name">Laura</p>
      </section>
      <NavLink to="/shop" className="about-page__continue">Continue Shopping</NavLink>
    </Page>
  );
};

export { About as default };