import React from 'react';
import useAdmin from './hooks/useAdmin';
import Gallery, { useGallery } from './Gallery';
import Settings from './Settings';
import './scss/cms.scss';

const CmsPanel = ({ children }) => {
  const [admin, { signOut }] = useAdmin();
  const [galleryOpen, { open: openGallery, close: closeGallery }] = useGallery();

  return admin ? (
    <aside className="cms__settings">
      <div className="cms__settings__content">
        <Settings confirmation={true} icon="/cms/images/iconmonstr-log-out-14.svg" label="Logout" invertIcon={true} onClick={async () => await signOut()} />
        <Settings icon="/cms/images/iconmonstr-picture-6.svg" label="Gallery" invertIcon={true} onClick={() => openGallery()} />
        { galleryOpen ? <Gallery allowSelect={false} onClose={closeGallery} /> : null }
        {children}
      </div>
    </aside>
  ) : null;
};

export { CmsPanel as default };