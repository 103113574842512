import React from 'react';
import Page from '../Page';
import '../../scss/pages/not-found.scss';

const NotFound = () => {
  return (
    <Page title="Page Not Found" className="not-found-page" description="Sorry, Page Not Found!" noIndex={true}>
      <h2 className="not-found-page__header">Sorry, Page Not Found!</h2>
      <p className="not-found-page__info">Please use the navigation above to find what you are looking for.</p>
    </Page>
  );
};

export { NotFound as default };