import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { createPortal } from 'react-dom';
import useAdmin from './hooks/useAdmin';
import useDynamic from './hooks/useDynamic';
import dynamicReducer, { START_EDIT, STOP_EDIT, TOGGLE_HISTORY, UPDATE_ACTIVE } from './reducers/dynamicReducer';
import Editable from './Editable';
import EditPanel from './EditPanel';

const Dynamic = (
  {
    id,
    query,
    mutation,
    historyQuery,
    typename,
    component: Component,
    editComponent: Edit,
    editHeader,
    autoEdit = false,
    historyFormatters = [],
    deleteEnabled = false,
    cacheEnabled = false,
    ...rest
  },
) => {
  const [admin] = useAdmin();
  const [{ active = false, editing = false, historyOpen = false, hover = false }, dispatch] = useReducer(dynamicReducer, {});
  const [
    {
      loading,
      error,
      data,
      notFound,
      submitting,
      failure,
      response,
      removing,
      removeError,
      removeResponse,
      hasChanged,
      historyLoading,
      historyError,
      history,
      historyShowMore,
    },
    {
      submit,
      updatePreview,
      close,
      remove,
      loadHistory,
    },
  ] = useDynamic({ id, typename: typename, query, mutation, historyQuery, cacheEnabled });
  const [submitFormatter, setSubmitFormatter] = useState();
  const [closeCallback, setCloseCallback] = useState();
  const [validator, setValidator] = useState();
  const [invalid, setInvalid] = useState();
  const onHover = useCallback((e) => dispatch({ type: UPDATE_ACTIVE, active: !!(Edit && e.shiftKey), hover: true }), [dispatch, Edit]);
  const onHoverStop = useCallback(() => dispatch({ type: UPDATE_ACTIVE, active: false, hover: false }), [dispatch]);
  const toggleHistory = useCallback(() => dispatch({ type: TOGGLE_HISTORY }), [dispatch]);

  const onEdit = (e) => {
    if (Edit && active && !editing) {
      e.preventDefault();
      dispatch({ type: START_EDIT });
    }
  };

  useEffect(() => {
    if (id && Edit && autoEdit) {
      dispatch({ type: START_EDIT });
    }
  }, [Edit, id, autoEdit, dispatch])

  const onClose = useCallback((data) => {
    dispatch({ type: STOP_EDIT });
    close(data);
    setInvalid(undefined)
    if (closeCallback) {
      closeCallback();
    }
  }, [close, closeCallback, setInvalid]);

  const onSetSubmitFormatter = useCallback((formatter) => {
    setSubmitFormatter(() => formatter);
  }, [setSubmitFormatter]);

  const onSetCloseCallback = useCallback((callback) => {
    setCloseCallback(() => callback);
  }, [setCloseCallback]);

  const onSetValidator = useCallback((validator) => {
    setValidator(() => validator);
  }, [setValidator]);

  useEffect(() => {
    if (historyOpen) {
      loadHistory();
    }
  }, [historyOpen, loadHistory]);

  useEffect(() => {
    if (!submitting && !error && response) {
      onClose(response);
    }
  }, [submitting, response, error, onClose]);

  return admin ?
    <>
      <Editable active={active} hover={hover} onEdit={onEdit} onHover={onHover} onHoverStop={onHoverStop}>
        <Component {...rest} id={id} loading={loading} error={error} data={data} notFound={notFound}/>
      </Editable>
      {editing ?
        createPortal(
          <EditPanel
            header={editHeader ?? `Edit Content: ${id}`}
            id={id}
            loading={loading}
            data={data}
            submitting={submitting}
            error={failure}
            response={response}
            removing={removing}
            removeError={removeError}
            hasChanged={hasChanged}
            close={onClose}
            submit={submit}
            remove={remove}
            historyOpen={historyOpen}
            historyLoading={historyLoading}
            historyError={historyError}
            history={history}
            historyShowMore={historyShowMore}
            updatePreview={updatePreview}
            submitFormatter={submitFormatter}
            toggleHistory={toggleHistory}
            historyFormatters={historyFormatters}
            deleteEnabled={deleteEnabled}
            invalid={invalid}
            validator={validator}
            setInvalid={setInvalid}
          >
            <Edit {...rest} id={id} data={data} invalid={invalid} removed={removeResponse?.complete} updatePreview={updatePreview} setSubmitFormatter={onSetSubmitFormatter} setValidator={onSetValidator} setCloseCallback={onSetCloseCallback}/>
          </EditPanel>
          , document.body,
        )
        : null}
    </>
    : <Component {...rest} id={id} loading={loading} error={error} data={data} notFound={notFound}/>;
};

export { Dynamic as default, useDynamic };