import React, { useEffect, useState } from 'react';
import '../scss/spinner.scss';

const Spinner = ({ instant = false, dark = true, small = false, center = false }) => {
  const [showing, setShowing] = useState(instant);

  useEffect(() => {
    if (!instant) {
      const timer = setTimeout(() => {
        setShowing(true);
      }, 1000);
      return () => {
        clearTimeout(timer);
      }
    }
  }, [instant, setShowing]);

  return showing ? <div className={`spinner ${dark ? `spinner--dark` : ``} ${small ? `spinner--small` : ``} ${center ? `spinner--center` : ``}`.trim()} /> : null;
}

export { Spinner as default };