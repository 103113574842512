import { useCallback, useState } from 'react';

const PAYPAL_CLIENT_ID = 'AVkwZeh0vjbMJnRecKjUXJsqRhLoQDg1bRTjaRT1y__z583ljuktFuT8vKg_yUolD9oe7vYRAH0qsFFc';
const SDK_ID = 'paypal-sdk-script';

const usePayPal = () => {
  const [paypal, setPayPal] = useState();

  const loadPayPal = useCallback(() => {
    const existingScript = document.getElementById(SDK_ID);
    if (existingScript) return setPayPal(window.paypal);

    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&currency=GBP`;
    script.id = SDK_ID;
    script.onload = () => setPayPal(window.paypal);

    document.body.appendChild(script);
  }, [setPayPal]);

  return [paypal, { loadPayPal }];
};

export { usePayPal as default };