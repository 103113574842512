import React, { useEffect } from 'react';
import Settings from '../cms/Settings';
import EditField from '../cms/EditField';

const ContactSettingsPanel = ({ data = {}, invalid, updatePreview, setValidator }) => {
  useEffect(() => {
    setValidator((data = {}) => {
      const errors = {};
      if (data?.whatsapp?.length && !/^\+\d+$/.test(data.whatsapp)) {
        errors.whatsapp = 'Please enter a valid WhatsApp number beginning with +44';
      }
      if (data?.phone?.length && !/^[0-9 +]+$/.test(data.phone)) {
        errors.phone = 'Please enter a valid phone number';
      }
      if (data?.email?.length && !/^[^@]+@[^@]+\.[^@]+$/.test(data.email)) {
        errors.email = 'Please enter a email address';
      }
      return errors;
    });
  }, [setValidator]);

  return <>
    <EditField label="WhatsApp Number:" value={data.whatsapp ?? ''} error={invalid?.whatsapp} onChange={(whatsapp) => updatePreview({ ...data, whatsapp })}/>
    <EditField label="Phone Number:" value={data.phone ?? ''} error={invalid?.phone} onChange={(phone) => updatePreview({ ...data, phone })}/>
    <EditField label="Email" value={data.email ?? ''} error={invalid?.email} onChange={(email) => updatePreview({ ...data, email })}/>
  </>;
};

const ContactSettings = () => {
  return <Settings
    id="contact-settings"
    typename="ContactSettings"
    icon="/images/iconmonstr-email-12.svg"
    label="Contact Settings"
    invertIcon={true}
    panel={ContactSettingsPanel}
    cachedEnabled={true}
  />
};

export { ContactSettings as default }