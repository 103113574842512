import React from 'react';
import Gallery, { useGallery } from './Gallery';
import './scss/edit-image-list.scss';

const EditImageList = ({ items, onChange, error }) => {
  const [showing, { open, close, parse }] = useGallery();

  const onCloseGallery = (selected) => {
    close();
    if (selected) {
      onChange([ ...new Set([...(items ?? []), ...selected.map(({ url }) => url)]) ]);
    }
  };

  const back = (image) => {
    const index = items?.indexOf(image) ?? -1;
    if (index !== -1) {
      const newList = [...items];
      newList[index] = newList[index-1];
      newList[index-1] = image;
      onChange(newList);
    }
  };

  const next = (image) => {
    const index = items?.indexOf(image) ?? -1;
    if (index !== -1) {
      const newList = [...items];
      newList[index] = newList[index + 1];
      newList[index+1] = image;
      onChange(newList);
    }
  };

  const remove = (image) => {
    onChange(items?.filter((value) => value !== image));
  };

  return <div className="cms__edit__content__field-container">
    <label className="cms__edit__content__field-container__label">Images:</label>
    { error ? <p className="cms__edit__content__field-container__error">{error}</p> : null }
    <div className="cms__image-list">
      { <ul className={`cms__image-list__items ${error ? 'cms__image-list__items--error' : ''}`}>
          { items?.map((value, index) => {
            const { name, thumb } = parse(value);
            return (<li key={value} className="cms__image-list__items__item">
              <img className="cms__image-list__items__item__image" title={name} src={thumb} alt={value}/>
              <div className="cms__image-list__items__item__move">
                { index > 0 ? <span className="cms__image-list__items__item__move__back" title="Move Left" onClick={() => back(value)} /> : null }
                { index < items.length - 1 ? <span className="cms__image-list__items__item__move__next" title="Move Right" onClick={() => next(value)} /> : null }
              </div>
              <span className="cms__image-list__items__item__remove" title="Remove" onClick={() => remove(value)} />
            </li>);
          })}
          <li key="add" className="cms__image-list__items__item cms__image-list__items__item--add" onClick={open}>
            <img className="cms__image-list__items__item__image" src="/cms/images/iconmonstr-picture-8.svg" title="Add Images" alt="Add"/>
          </li>
        </ul>
      }
      {showing ? <Gallery multiple={true} onClose={onCloseGallery}/> : null}
    </div>
  </div>
};

export { EditImageList as default };