import { useCallback, useContext } from 'react';
import { store } from '../providers/ShopProvider';

const SESSION_KEY = '~~basket-session~~';

const useBasket = () => {
  const { basket, basketAdd, basketRemove, basketToggle, basketClear } = useContext(store);

  const setSession = useCallback((sessionId) => {
    try {
      if (sessionId) {
        localStorage.setItem(SESSION_KEY, sessionId);
      } else {
        localStorage.removeItem(SESSION_KEY);
      }
    } catch (e) {
      console.log('Failed to store basket session', e);
    }
  }, []);

  const getSession = useCallback(() => {
    try {
      return localStorage.getItem(SESSION_KEY);
    } catch (e) {
      console.log('Failed to get basket session', e);
    }
  }, []);

  const deleteSession = useCallback(() => {
    try {
      localStorage.removeItem(SESSION_KEY);
    } catch (e) {
      console.log('Failed to delete basket session', e);
    }
  }, []);

  return [basket, { basketAdd, basketRemove, basketToggle, basketClear, setSession, getSession, deleteSession }];
};

export { useBasket as default };