import React from 'react';
import { NavLink } from 'react-router-dom';
import '../scss/picture-nav.scss';

const PictureNavLink = ({ to, withSpaces, children }) => {
  const external = /^http/i.test(to);
  return (
    <figure className={`picture-nav__item ${!withSpaces ? 'picture-nav__item--no-space' : ''}`}>
    {
      external ?
        <a href={to} target="_blank" rel="noopener noreferrer" className={`picture-nav__item ${!withSpaces ? 'picture-nav__item--no-space' : ''}`}
           children={children}/>
        : <NavLink to={to} className="picture-nav__item__link" children={children}/>
    }
    </figure>
  )
}

const PictureNavItem = ({ link, image, title, withTitles, withSpaces }) => {
  return (
    <PictureNavLink to={`${link}`} withSpaces={withSpaces}>
      <img alt={title} className="picture-nav__item__image" src={image}/>
      {withTitles ?
        <figcaption className="picture-nav__item__info">
          <span className="picture-nav__item__info__title">{title}</span>
        </figcaption>
        : null
      }
    </PictureNavLink>
  );
};

const PictureNav = ({ links = [], withTitles = true, withSpaces = true }) => {
  return (
    <section className="picture-nav">
      {links.map((item) => <PictureNavItem key={item.image} {...item} withTitles={withTitles} withSpaces={withSpaces} />)}
    </section>
  );
};

export { PictureNav as default };