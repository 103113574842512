import { useCallback, useReducer } from 'react';
import API, { graphqlOperation } from '@aws-amplify/api';

const requests = {};
const keyCounts = {};
const blockedKeys = {};

const generateKey = (...args) => JSON.stringify(args);

const doOperation = (ql, vars) => new Promise(async (resolve, reject) => {
  const key = generateKey(ql, vars);
  if (blockedKeys[key]) {
    reject(new Error('API error detected, request blocked'));
    return;
  }

  const timeBucket = `${Math.floor(Date.now() / (1000 * 2))}-${key}`;
  keyCounts[timeBucket] = (keyCounts[timeBucket] || 0) + 1;
  if (keyCounts[timeBucket] > 10) {
    blockedKeys[key] = true;
    console.warn('API error detected, blocking request:', [ql, vars]);
  }

  if (requests[key]) {
    requests[key].handlers.push({ resolve, reject });
  } else {
    requests[key] = {
      handlers: [{ resolve, reject }],
    };
    const operation = graphqlOperation(ql, {
      ...vars,
    });

    try {
      const response = await API.graphql(operation);
      requests[key].handlers.forEach(({ resolve }) => resolve(response));
    } catch(e) {
      requests[key].handlers.forEach(({ reject }) => reject(e));
    }
    delete requests[key];
  }
});

const graphReducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true, error: undefined, complete: false };
    case 'DATA':
      return { ...state, loading: false, error: undefined, data: action.data, complete: true };
    case 'ERROR':
      return { ...state, loading: false, error: action.error, data: undefined, complete: true };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

const useGraph = () => {
  const [state, dispatch] = useReducer(graphReducer, {
    loading: false,
    data: undefined,
    error: undefined,
    complete: false,
  });

  const request = useCallback(async (ql, vars = {}) => {
    dispatch({ type: 'LOADING' });
    try {
      const { data: rawData } = await doOperation(ql, vars);
      const data = rawData ? rawData : undefined;
      dispatch({ type: 'DATA', data });
      return { data };
    } catch (error) {
      if (typeof (error) === 'string') {
        dispatch({ type: 'ERROR', error });
        return { error };
      } else {
        const { message, errors = [] } = error;
        const err = errors.length ? errors[0].message : message;
        dispatch({ type: 'ERROR', error: err });
        return { error: err };
      }
    }
  }, [dispatch]);

  return [state, { request }];
};

export { useGraph as default };