const SET_PRODUCTS = 'SET_PRODUCTS';
const UPDATE_SORT_BY = 'UPDATE_SORT_BY';
const UPDATE_FILTERS = 'UPDATE_FILTERS';
const SET_NEXT_TOKEN = 'SET_NEXT_TOKEN';

const searchReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_SORT_BY:
      return { ...state, searchBy: action.searchBy, orderBy: action.orderBy, limit: action.limit, nextToken: undefined }
    case UPDATE_FILTERS:
      const productTypeIds = [...(action.productTypeIds ?? [])].sort();
      return state.productTypeIds?.join(',') === productTypeIds.join(',') ?
        { ...state, nextToken: undefined }
        : { ...state, productTypeIds, nextToken: undefined };
    case SET_PRODUCTS:
      const products = (state.nextToken && state.products?.length) ? [...(state.products ?? []), ...action.products] : action.products;
      return { ...state, products };
    case SET_NEXT_TOKEN:
      return { ...state, nextToken: action.token };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

export { searchReducer as default, UPDATE_FILTERS, SET_PRODUCTS, UPDATE_SORT_BY, SET_NEXT_TOKEN };