import React, { useEffect, useState } from 'react';
import useSearch from './hooks/useSearch';
import ProductList from './ProductList';
import Spinner from './Spinner';
import ErrorNotice from './ErrorNotice';

import '../scss/trending.scss';

const Trending = () => {
  const [list, setList] = useState();
  const [items, setItems] = useState();
  const [{ loading, error, products }] = useSearch({ searchBy: 'PROMOTED', orderBy: 'DESCENDING', limit: 4 });
  const [{ back, next }, setShowButtons] = useState({ back: false, next: false });

  useEffect(() => {
    setList(document.querySelector('.trending__content__products'));
  }, [setList, products]);

  useEffect(() => {
    setItems((products ?? []).slice(0, 4));
  }, [setItems, products]);

  useEffect(() => {
    if (list) {
      if (list.scrollWidth !== list.clientWidth) {
        const updateState = ({ scrollLeft = 0, scrollWidth = 0, clientWidth = 0 } = {}) => {
          setShowButtons({ back: scrollLeft > 0, next: scrollLeft !== scrollWidth - clientWidth });
        };

        const onScroll = (e) => {
          updateState(e.target);
        };

        updateState(list);
        list.addEventListener('scroll', onScroll);
        return () => {
          list.removeEventListener('scroll', onScroll);
        };
      }
    }
  }, [list, setShowButtons]);

  const scrollThumbnails = (backwards) => {
    if (list) {
      const diff = 240;
      list.scrollTo(backwards ? list.scrollLeft - diff : list.scrollLeft + diff, 0);
    }
  };

  return <section className="trending">
    { loading ? <Spinner /> : null }
    { error ? <ErrorNotice error={error} /> : null }
    { items && items.length ? <>
        <h2 className="trending__header">Trending Now</h2>
        <div className="trending__content">
          <button className={`trending__content__back ${back ? 'trending__content__back--visible' : ''}`} onClick={() => scrollThumbnails(true)} />
          <ProductList className="trending__content__products" products={items} includeWishlist={false} noWrap={true}/>
          <button className={`trending__content__next ${next ? 'trending__content__next--visible' : ''}`} onClick={() => scrollThumbnails(false)} />
        </div>
      </>
    : null }
  </section>
};

export { Trending as default };