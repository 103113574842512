import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const Editable = ({ active, hover, onEdit, onHover, onHoverStop, children }) => {
  const element = useRef();
  const [pos, setPos] = useState();
  const [scrollTop, setScrollTop] = useState();

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.scrollingElement.scrollTop || 0);
    };
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [setScrollTop]);

  const onMouseMove = (e) => {
    if (element.current) {
      const firstChild = element.current.querySelector(':scope > *');
      if (firstChild) {
        let { top, left, width, height } = firstChild.getBoundingClientRect() || {};
        if (scrollTop > 0 && top > 0) {
          top += scrollTop;
        } else if (top < 0) {
          top = scrollTop - Math.abs(top);
        }
        setPos({
          top: `${top}px`,
          left: `${left}px`,
          width: `${width}px`,
          height: `${height}px`,
        });
      }
    }
    onHover(e);
  };

  useEffect(() => {
    if (!hover) {
      setPos(undefined);
    }
  }, [hover]);

  return <div
    ref={element}
    onClick={onEdit}
    onMouseMove={onMouseMove}
    onMouseLeave={onHoverStop}
    className="cms__editable"
  >
    {createPortal(<div
      className={`cms__editable__selector ${hover ? 'cms__editable__selector--hover' : ''} ${active ? 'cms__editable__selector--active' : ''}`}
      style={{ ...(pos || {}) }}
    />, document.body)}
    {children}
  </div>;
};

export { Editable as default };