import { hasDiff } from '../utils/dotify';

const UPDATE_FETCH_STATE = 'UPDATE_FETCH_STATE';
const UPDATE_SUBMIT_STATE = 'UPDATE_SUBMIT_STATE';
const UPDATE_REMOVE_STATE = 'UPDATE_REMOVE_STATE';
const UPDATE_PREVIEW = 'UPDATE_PREVIEW';
const FINISH = 'FINISH';

const dynamicHookReducer = (state, action) => {
  const { [state.type.query]: data, [state.type.mutation]: response, [state.type.remove]: removeResponse } = action.data ?? {};
  switch (action.type) {
    case UPDATE_FETCH_STATE:
      return { ...state, loading: action.loading, data: data ?? undefined, notFound: data === null, error: action.error };
    case UPDATE_SUBMIT_STATE:
      return { ...state, submitting: action.loading, response: response ?? undefined, failure: action.error };
    case UPDATE_REMOVE_STATE:
      return { ...state, removing: action.loading, removeResponse: removeResponse ?? undefined, removeError: action.error };
    case UPDATE_PREVIEW:
      return { ...state, preview: data ?? undefined, hasChanged: hasDiff(state.data, data) };
    case FINISH:
      return {
        ...state,
        loading: false,
        data: action.data ?? state.data,
        error: undefined,
        preview: undefined,
        submitting: false,
        failure: undefined,
        response: undefined,
        hasChanged: false,
      };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

export {
  dynamicHookReducer as default,
  UPDATE_FETCH_STATE,
  UPDATE_SUBMIT_STATE,
  UPDATE_REMOVE_STATE,
  UPDATE_PREVIEW,
  FINISH
};