const isArray = (obj) => obj && obj.constructor === Array;
const isObject = (obj) => obj && obj.constructor === Object;
const buildPath = (...args) => (args || []).filter(i => i.length).join('.').replace(/\s+/g, '');
const merge = ({ dots, keys }, { dots: newDots, keys: newKeys }) => ({ dots: { ...dots, ...newDots }, keys: { ...keys, ...newKeys } });

const _dotify = ({ obj, path = '', ignore = [], keys = [] }) => {
  let values = { dots: {}, keys: {} };
  if (isArray(obj)) {
    const clone = [...obj];
    clone.forEach((value, index) => {
      values = merge(values, _dotify({ obj: value, path: buildPath(path, `[${index}]`), ignore, keys }));
    });

  } else if (isObject(obj)) {
    Object.keys(obj).forEach((key) => {
      values = merge(values, _dotify({ obj: obj[key], path: buildPath(path, key), ignore, keys }));
    });

  } else {
    if (keys && keys.length && keys.includes(path)) {
      values.keys[path] = obj;
    }
    if (!ignore.length || !ignore.some(pattern => pattern.test(path))) {
      values.dots[path] = obj;
    }
  }
  return values;
};

const toArray = ({ dots } = {}) => Object.keys(dots).map((key) => `${key}:${dots[key]}`).sort();

const dotify = (obj) => _dotify({ obj });
const equal = (obj1, obj2) => {
  return JSON.stringify(toArray(dotify(obj1))) === JSON.stringify(toArray(dotify(obj2)));
};
const hasDiff = (obj1, obj2) => !equal(obj1, obj2);
const getDiff = ({ older = {}, newer = {}, historyFormatters = [] }) => {
  const diff = {};
  const oldDots = dotify(older);
  const newDots = dotify(newer);

  const formatKey = (key, dots) => {
    const { pattern, formatter } = historyFormatters.find(({ pattern }) => pattern.test(key)) || {};
    if (typeof formatter === 'function') {
      return key.replace(pattern, (...replace) => formatter({ key, values: dots, replace }));
    }
    return key;
  }

  Object.keys(oldDots.dots).forEach((key) => {
    const formattedKey = formatKey(key, oldDots.dots);
    if (formattedKey) {
      if (!(key in newDots.dots)) {
        diff[formattedKey] = 'removed';
      } else if (oldDots.dots[key] !== newDots.dots[key]) {
        diff[formattedKey] = newDots.dots[key] === null || newDots.dots[key].length === 0 ? 'removed' : 'updated';
      }
    }
  });

  Object.keys(newDots.dots).forEach((key) => {
    if (newDots.dots[key] !== null && newDots.dots[key].length !== 0) {
      if (!(key in oldDots.dots) || oldDots.dots[key] === null || oldDots.dots[key].length === 0) {
        const formattedKey = formatKey(key, oldDots.dots);
        if (formattedKey) {
          diff[formatKey(key, newDots.dots)] = 'added';
        }
      }
    }
  });

  return diff;
}

export { dotify, equal, getDiff, hasDiff };
